export const FormatItalicIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      fill="currentColor"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8.82004 6.75H10.32L8.67004 14.25H7.17004L8.82004 6.75ZM10.08 3.75C9.93171 3.75 9.7867 3.79399 9.66337 3.8764C9.54003 3.95881 9.4439 4.07594 9.38713 4.21299C9.33037 4.35003 9.31552 4.50083 9.34446 4.64632C9.37339 4.7918 9.44482 4.92544 9.54971 5.03033C9.6546 5.13522 9.78824 5.20665 9.93373 5.23559C10.0792 5.26453 10.23 5.24968 10.3671 5.19291C10.5041 5.13614 10.6212 5.04001 10.7036 4.91668C10.7861 4.79334 10.83 4.64834 10.83 4.5C10.83 4.30109 10.751 4.11032 10.6104 3.96967C10.4697 3.82902 10.279 3.75 10.08 3.75Z" />
    </svg>
  );
};

export default FormatItalicIcon;
