export const ImageIcon: React.ComponentType<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      fill="currentColor"
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17 6.5H11.75L10.25 5H5C4.175 5 3.5 5.675 3.5 6.5V15.5C3.5 16.325 4.175 17 5 17H17C17.825 17 18.5 16.325 18.5 15.5V8C18.5 7.175 17.825 6.5 17 6.5ZM17 15.5H5V6.5H9.65L11.15 8H17V15.5ZM15.5 14L12.65 10.25H12.5L10.325 13.1L8.75 11.075L6.5 14H15.5ZM9.5 9.125C9.5 8.525 8.975 8 8.375 8C7.775 8 7.25 8.525 7.25 9.125C7.25 9.725 7.775 10.25 8.375 10.25C8.975 10.25 9.5 9.725 9.5 9.125Z" />
    </svg>
  );
};

export default ImageIcon;
