import React, { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import { PillButton } from './pill-button';

export interface QuickDataFilterGroupProps {
  activeFilter: string;
  defaultFilter: string;
  items: { count: number; label: string; value: string }[];
  setActiveFilter: Dispatch<SetStateAction<string>>;
}

export const QuickDataFilterGroup: React.ComponentType<
  QuickDataFilterGroupProps
> = ({ activeFilter, defaultFilter, items, setActiveFilter }) => {
  return (
    <div className="flex items-center">
      {items.map(({ count, label, value }, index) => (
        <div
          key={value}
          className={clsx(
            index === 0 ? 'pr-4' : 'px-4',
            index !== items.length - 1 && 'border-r border-secondary-grey-light'
          )}
        >
          <PillButton
            active={activeFilter === value}
            count={count}
            disabled={count === 0}
            label={label}
            onToggle={() =>
              activeFilter === value
                ? setActiveFilter(defaultFilter)
                : setActiveFilter(value)
            }
          />
        </div>
      ))}
    </div>
  );
};

export default QuickDataFilterGroup;
