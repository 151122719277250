// Modules
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import Typography from './typography';

export type AlertVariant = 'error' | 'info' | 'success' | 'warning';

export interface AlertProps {
  description: React.ReactNode;
  variant: AlertVariant;
}

export const Alert: React.ComponentType<AlertProps> = ({
  description,
  variant,
}) => {
  return (
    <div
      className={clsx(
        'flex items-center gap-3 border p-4 sm:rounded-md',
        variant === 'error' && 'bg-chart-red-light-2 text-status-red',
        variant === 'info' && 'bg-blue-50 text-chart-blue-dark',
        variant === 'success' &&
          'bg-chart-green-light-2 text-primary-green-dark',
        variant === 'warning' && 'bg-chart-orange-light text-chart-red-dark'
      )}
    >
      {/* Icon */}
      {variant === 'error' && (
        <XCircleIcon className="h-5 w-5 text-status-red" />
      )}
      {variant === 'info' && <InformationCircleIcon className="h-5 w-5" />}
      {variant === 'success' && (
        <CheckCircleIcon className="h-5 w-5 text-status-green" />
      )}
      {variant === 'warning' && <ExclamationCircleIcon className="h-5 w-5" />}
      {/* Content */}
      <Typography className="min-w-0 flex-1" variant="button">
        {description}
      </Typography>
    </div>
  );
};

export default Alert;
