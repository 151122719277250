import { SVGProps } from 'react';

export const UpvoteIcon: React.FC<SVGProps<SVGElement>> = ({
  className,
  height,
  width,
}) => {
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.781 2.37499C12.4 1.89999 11.6 1.89999 11.219 2.37499L3.219 12.375C3.10164 12.5222 3.02817 12.6995 3.00703 12.8866C2.98589 13.0737 3.01794 13.2629 3.09949 13.4326C3.18105 13.6023 3.3088 13.7455 3.46809 13.8459C3.62737 13.9463 3.81173 13.9997 4 14H8V21C8 21.2652 8.10536 21.5196 8.29289 21.7071C8.48043 21.8946 8.73478 22 9 22H15C15.2652 22 15.5196 21.8946 15.7071 21.7071C15.8946 21.5196 16 21.2652 16 21V14H20C20.1883 13.9997 20.3726 13.9463 20.5319 13.8459C20.6912 13.7455 20.819 13.6023 20.9005 13.4326C20.9821 13.2629 21.0141 13.0737 20.993 12.8866C20.9718 12.6995 20.8984 12.5222 20.781 12.375L12.781 2.37499ZM15 12H14V20H10V12H6.081L12 4.60099L17.919 12H15Z"
        fill="currentcolor"
      />
    </svg>
  );
};

export default UpvoteIcon;
