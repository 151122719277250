import { AlertProvider } from '@/contexts/alert-context';
import { ContactFreshProvider } from '@/contexts/contact-fresh-context';
import { CookieBannerProvider } from '@/contexts/cookie-banner-context';

interface Props {
  children?: React.ReactNode;
}

const ContextProvider: React.ComponentType<Props> = ({ children }) => {
  return (
    <AlertProvider>
      <ContactFreshProvider>
        <CookieBannerProvider>{children}</CookieBannerProvider>
      </ContactFreshProvider>
    </AlertProvider>
  );
};

export default ContextProvider;
