export interface IconButtonProps {
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
}

export const IconButton: React.ComponentType<IconButtonProps> = ({
  children,
  onClick,
  type = 'button',
}) => {
  return (
    <button
      className="flex h-11 w-11 items-center justify-center rounded-lg text-primary-grey-dark hover:bg-secondary-grey-light-2 hover:shadow active:bg-secondary-grey-light active:shadow-none disabled:cursor-not-allowed"
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default IconButton;
