import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  Skeleton,
  BlockHeading,
  DiscoverBlockCard,
  useDiscoverBlockAnnouncementsAndUpdatesQuery,
  MediaAnnouncement,
  MediaUpdate,
} from '../../../index';

interface Props {
  client?: ApolloClient<NormalizedCacheObject>;
  content: {
    heading: string;
  };

  hub: string;
}

export const DiscoverBlock: React.ComponentType<Props> = ({
  client,
  content = { heading: 'Discover' },
  hub,
}) => {
  const { data, loading } = useDiscoverBlockAnnouncementsAndUpdatesQuery({
    client,
    variables: {
      hub,
    },
  });

  const latestAnnualReport =
    data?.discoverBlockAnnouncementsAndUpdates?.latestAnnualReport;
  const latestPresentation =
    data?.discoverBlockAnnouncementsAndUpdates?.latestInvestorPresentation;
  const latestQuarterlyCashflowReport =
    data?.discoverBlockAnnouncementsAndUpdates?.latestQuarterlyCashflowReport;
  const latestUpdate = data?.discoverBlockAnnouncementsAndUpdates?.latestUpdate;

  const renderDiscoverCards = () => (
    <div className="flex flex-col gap-4">
      <BlockHeading>{content.heading}</BlockHeading>
      <div className="grid grid-cols-1 gap-4 md:grid md:grid-cols-2 md:gap-6 lg:grid-cols-3">
        {loading ? (
          <>
            <Skeleton loading height={316} variant="rect" />
            <Skeleton loading height={316} variant="rect" />
            <Skeleton loading height={316} variant="rect" />
          </>
        ) : (
          <>
            {latestAnnualReport && (
              <DiscoverBlockCard
                announcement={latestAnnualReport as MediaAnnouncement}
                layout="vertical"
              />
            )}
            {latestPresentation && (
              <DiscoverBlockCard
                announcement={latestPresentation as MediaAnnouncement}
                layout="vertical"
              />
            )}
            {latestUpdate ? (
              <DiscoverBlockCard
                layout="vertical"
                update={latestUpdate as MediaUpdate}
              />
            ) : latestQuarterlyCashflowReport ? (
              <DiscoverBlockCard
                announcement={
                  latestQuarterlyCashflowReport as MediaAnnouncement
                }
                layout="vertical"
              />
            ) : null}
          </>
        )}
      </div>
    </div>
  );

  return (
    <div className="mx-auto w-full max-w-screen-xl space-y-6 px-4 md:px-6">
      {renderDiscoverCards()}
    </div>
  );
};
