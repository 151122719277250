import clsx from 'clsx';
import styles from './card.module.css';

export interface CardProps {
  children?: React.ReactNode;
  className?: string;
  noBorder?: boolean;
  noHover?: boolean;
  transparent?: boolean;
  variant?: 'default';
}

export const Card: React.ComponentType<CardProps> = ({
  children,
  className,
  noBorder = false,
  noHover = false,
  transparent = false,
  variant = 'default',
}) => {
  const style = [styles[variant]];

  if (transparent) {
    style.push(styles['transparent']);
  }

  if (noBorder) {
    style.push(styles['no-border']);
  }

  if (noHover) {
    style.push(styles['no-hover']);
  }

  return <div className={clsx(style.join(' '), className)}>{children}</div>;
};
