import { useCallback } from 'react';
import { RichUtils } from 'draft-js';
import {
  FormatBoldIcon,
  FormatItalicIcon,
  FormatStrikethroughIcon,
  FormatUnderlineIcon,
} from '../icons';
import { useEditor } from './context';

const INLINE_STYLES = [
  { Icon: FormatBoldIcon, style: 'BOLD' },
  { Icon: FormatItalicIcon, style: 'ITALIC' },
  { Icon: FormatUnderlineIcon, style: 'UNDERLINE' },
  { Icon: FormatStrikethroughIcon, style: 'STRIKETHROUGH' },
];

const InlineStyles: React.ComponentType = () => {
  const { editorState, setEditorState } = useEditor();

  const onMouseDown = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.preventDefault();
    },
    []
  );

  const toggleInlineStyle = useCallback<
    (style: string) => React.MouseEventHandler<HTMLButtonElement>
  >(
    (style) => {
      return (e) => {
        e.preventDefault();

        setEditorState((prevState) =>
          RichUtils.toggleInlineStyle(prevState, style)
        );
      };
    },
    [setEditorState]
  );

  return (
    <div className="flex items-center gap-2" onMouseDown={onMouseDown}>
      {INLINE_STYLES.map((inlineStyle) => (
        <button
          key={inlineStyle.style}
          type="button"
          onClick={toggleInlineStyle(inlineStyle.style)}
        >
          <inlineStyle.Icon
            className={
              editorState.getCurrentInlineStyle().has(inlineStyle.style)
                ? 'text-text-main'
                : 'text-text-grey'
            }
          />
        </button>
      ))}
    </div>
  );
};

export default InlineStyles;
