'use client'; // https://www.blocknotejs.org/docs/advanced/nextjs - can't use blocknote in server side
import React, { useEffect, useState } from 'react';
import { Block } from '@blocknote/core';
import { useCreateBlockNote } from '@blocknote/react';
import DOMPurify from 'isomorphic-dompurify';

interface RenderBlockNoteProps {
  className?: string;
  content: Block[];
  plaintext?: boolean;
}

export const RenderBlockNote: React.FC<RenderBlockNoteProps> = ({
  className = 'blocknote prose font-body text-lg leading-normal text-hubs-primary prose-headings:text-hubs-primary prose-p:text-hubs-primary prose-a:text-hubs-primary prose-strong:text-hubs-primary prose-table:text-hubs-primary marker:text-hubs-primary list-inside list-disc',
  content,
  plaintext,
}) => {
  const [contentHTML, setcontentHTML] = useState<string>('');
  const editor = useCreateBlockNote();

  useEffect(() => {
    const convertDescriptionToHTML = async () => {
      if (content) {
        const html = await editor.blocksToHTMLLossy(content);
        setcontentHTML(html);
      }
    };
    convertDescriptionToHTML();
  }, [editor, content]);

  const blockContentToPlaintextHtml = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(contentHTML),
        }}
        className="text-hubs-secondary children:break-words"
      />
    );
  };

  const contentIsEmpty =
    Array.isArray(content) &&
    content.length === 1 &&
    Array.isArray(content[0].content) &&
    content[0].content.length === 0 &&
    content[0].children?.length === 0;

  if (contentIsEmpty || !contentHTML) {
    return null;
  }

  if (plaintext) {
    return blockContentToPlaintextHtml();
  }

  return (
    <div
      dangerouslySetInnerHTML={{ __html: contentHTML }}
      className={className}
    />
  );
};
