import React, { useState, useEffect } from 'react';
import { BellIcon } from '@heroicons/react-v2/24/outline';
import * as cookie from 'cookie';
import { useRouter } from 'next/router';
import { Button } from '../../button';
import { Typography } from '../../typography';

const BANNER_CLOSED_COOKIE = 'sticky_signup_banner_closed';

interface StickySignupBannerProps {
  isFullWebsiteReplacement?: boolean | null;
  onCloseClicked?: () => void;
  onJoinInvestorHubClicked?: () => void;
}

export const StickySignupBanner: React.FC<StickySignupBannerProps> = ({
  isFullWebsiteReplacement,
  onCloseClicked,
  onJoinInvestorHubClicked,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const router = useRouter();

  useEffect(() => {
    const cookies = cookie.parse(document.cookie);
    setIsVisible(cookies[BANNER_CLOSED_COOKIE] !== 'true');
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    document.cookie = cookie.serialize(BANNER_CLOSED_COOKIE, 'true', {
      maxAge: 30 * 24 * 60 * 60, // 30 days
      path: '/',
    });
    onCloseClicked?.();
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-x-0 bottom-0 z-50 bg-company-primary p-4 text-company-primary-text">
      <div className="mx-auto flex w-full max-w-screen-xl items-start justify-between gap-0 md:items-center md:gap-4">
        <div className="flex grow-0 flex-col justify-between gap-4 md:grow md:flex-row md:items-center">
          <div className="flex items-center gap-4">
            <BellIcon className="hidden size-8 stroke-2 text-company-primary-text sm:block" />
            <Typography
              className="text-left text-base md:text-lg"
              variant="heading-5"
            >
              Be the first to be notified about news, updates, and
              announcements.
            </Typography>
          </div>

          <div>
            <Button
              onClick={() => {
                router.push('/auth/signup');
                onJoinInvestorHubClicked?.();
              }}
            >
              {`Join our ${
                isFullWebsiteReplacement ? 'community' : 'investor hub'
              } `}
            </Button>
          </div>
        </div>
        <Button variant="tertiary" onClick={handleClose}>
          ✕
        </Button>
      </div>
    </div>
  );
};
