import { SVGProps } from 'react';

export const ActionDownIcon: React.FC<SVGProps<SVGElement>> = ({
  className,
  height,
  width,
}) => {
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.00021 9.54004H16.0002C16.0793 9.54185 16.1562 9.56652 16.2216 9.61106C16.287 9.65561 16.3381 9.71813 16.3688 9.79107C16.3994 9.86402 16.4083 9.94428 16.3944 10.0222C16.3805 10.1001 16.3443 10.1723 16.2902 10.23L12.2902 14.34C12.2521 14.3781 12.2069 14.4084 12.1572 14.429C12.1074 14.4496 12.0541 14.4602 12.0002 14.4602C11.9463 14.4602 11.893 14.4496 11.8433 14.429C11.7935 14.4084 11.7483 14.3781 11.7102 14.34L7.71021 10.23C7.65615 10.1723 7.61997 10.1001 7.60602 10.0222C7.59208 9.94428 7.60098 9.86402 7.63164 9.79107C7.6623 9.71813 7.71341 9.65561 7.77881 9.61106C7.84421 9.56652 7.9211 9.54185 8.00021 9.54004Z" />
    </svg>
  );
};

export default ActionDownIcon;
