import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface Props {
  editor: React.ReactNode;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
}

export const EditorModal: React.FC<Props> = ({ editor, onClose, open }) => {
  return (
    <Transition.Root as={Fragment} show={open}>
      <Dialog className="fixed inset-0 z-40 overflow-y-auto" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-primary-off-black/30" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="translate-y-full"
          enterTo="translate-y-0"
          leave="ease-in duration-200"
          leaveFrom="translate-y-0"
          leaveTo="translate-y-full"
        >
          <div className="flex h-full flex-col items-center p-6">
            <div className="flex-1" />
            <div className="w-full max-w-6xl">{editor}</div>
            <div className="flex-1" />
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};
