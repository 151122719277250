import { ArrowRightIcon } from '@heroicons/react-v2/24/outline';
import { MegaphoneIcon } from '@heroicons/react-v2/24/solid';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import Link from 'next/link';
import { Typography, PinIcon, UpdatesBlockMediaPreview } from '../../../index';
import CloudinaryImage from '../../ui/cloudinary-image';
import { MediaUpdateInBlock } from './block';

dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

interface Props {
  companyName: string;
  listingKey: string;
  logoUrl: string;
  marketKey: string;
  mediaUpdate: MediaUpdateInBlock;
}

export const UpdatesBlockGridItem: React.FC<Props> = ({
  logoUrl,
  mediaUpdate,
}) => {
  const url = `/activity-updates/${mediaUpdate.slug}`;

  const renderMedia = () => (
    <div className="relative h-full w-full pt-[56%]">
      <div className="absolute inset-0 h-full w-full overflow-hidden rounded-lg object-cover transition-shadow md:group-hover:shadow-lg">
        <div className="absolute inset-0 rounded-lg bg-gray-100 ring-1 ring-inset ring-gray-200" />
        <div className="absolute left-[50%] top-[50%] flex h-[64px] w-[64px] -translate-x-1/2 -translate-y-1/2 items-center justify-center overflow-hidden rounded-full bg-transparent fill-gray-200 ring-inset ring-gray-200">
          {logoUrl ? (
            <CloudinaryImage
              alt="Logo"
              className="h-full w-full object-contain"
              height={250}
              src={logoUrl}
              width={250}
            />
          ) : (
            <MegaphoneIcon className="h-8 w-8 fill-inherit" />
          )}
        </div>
        <div className="relative block h-full w-full">
          <UpdatesBlockMediaPreview mediaUpdate={mediaUpdate} />
        </div>
      </div>
    </div>
  );

  return (
    <Link
      key={mediaUpdate.id}
      className="group relative flex flex-col gap-2"
      href={url}
    >
      {renderMedia()}

      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <Typography
            className="font-body text-hubs-secondary"
            variant="body-small"
          >
            <time dateTime={dayjs(mediaUpdate.postedAt).format()}>
              {dayjs(mediaUpdate.postedAt).format('DD/MM/YYYY')}
            </time>
          </Typography>

          {mediaUpdate.isPinned ? (
            <div className="flex items-center gap-x-2 text-hubs-secondary">
              <PinIcon height={12} width={12} />
              <Typography className="font-body" variant="body-small">
                Pinned
              </Typography>
            </div>
          ) : null}
        </div>

        <div className="flex w-full flex-row items-center justify-between gap-4">
          <Typography
            className="line-clamp-2 font-body font-semibold text-company-primary"
            variant="body-regular"
          >
            {mediaUpdate.title}
          </Typography>
          <ArrowRightIcon className="hidden size-5 min-w-5 text-company-primary transition-transform group-hover:translate-x-1/4 md:flex" />
        </div>
      </div>
    </Link>
  );
};
