export const StarIcon: React.ComponentType<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      fill="currentColor"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.85891 1.5124C8.21891 0.407202 9.78252 0.407202 10.1413 1.5124L11.4253 5.4628C11.5038 5.70339 11.6563 5.91302 11.861 6.06173C12.0657 6.21044 12.3123 6.29062 12.5653 6.2908H16.7197C17.8825 6.2908 18.3649 7.7788 17.4253 8.4628L14.0653 10.9036C13.8602 11.0524 13.7074 11.2624 13.6289 11.5034C13.5504 11.7444 13.5503 12.0041 13.6285 12.2452L14.9125 16.1956C15.2725 17.3008 14.0065 18.2212 13.0645 17.5372L9.70451 15.0964C9.49956 14.9476 9.25279 14.8675 8.99952 14.8675C8.74624 14.8675 8.49947 14.9476 8.29451 15.0964L4.93452 17.5372C3.99372 18.2212 2.72891 17.3008 3.08772 16.1956L4.37172 12.2452C4.44992 12.0041 4.44978 11.7444 4.37132 11.5034C4.29285 11.2624 4.14008 11.0524 3.93491 10.9036L0.576115 8.464C-0.363485 7.78 0.120116 6.292 1.28172 6.292H5.43492C5.68817 6.29207 5.93496 6.21201 6.13994 6.06328C6.34493 5.91455 6.49761 5.70478 6.57612 5.464L7.86012 1.5136L7.85891 1.5124Z" />
    </svg>
  );
};

export default StarIcon;
