import React from 'react';
import clsx from 'clsx';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

export interface LinkProps extends NextLinkProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  target?: string;
}

export const Link: React.ComponentType<LinkProps> = ({
  children,
  className,
  disabled,
  target,
  ...props
}) => {
  return (
    <NextLink
      className={clsx(
        'bg-transparent text-company-primary underline hover:text-company-primary-text active:text-company-primary-text',
        disabled &&
          'cursor-not-allowed text-text-disabled hover:text-text-disabled active:text-text-disabled',
        className
      )}
      target={target}
      {...props}
    >
      {children}
    </NextLink>
  );
};

export default Link;
