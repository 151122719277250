import React from 'react';
import { Typography } from '../../../index';

export const WebinarLoadingScreen: React.ComponentType = () => {
  return (
    <>
      <div className="flex h-[650px] w-full animate-pulse items-center justify-center bg-gray-200 text-gray-300">
        <Typography variant="display-medium">Loading ...</Typography>
      </div>
      <div className="mx-auto w-full max-w-screen-xl space-y-8 p-4">
        <div className="space-y-4">
          <div className="h-4 w-1/2 animate-pulse rounded-md bg-gray-200" />
          <div className="h-4 w-3/4 animate-pulse rounded-md bg-gray-200" />
          <div className="h-4 w-2/3 animate-pulse rounded-md bg-gray-200" />
        </div>
        <div className="flex space-x-4">
          <div className="h-10 w-24 animate-pulse rounded-md bg-gray-200" />
          <div className="h-10 w-24 animate-pulse rounded-md bg-gray-200" />
        </div>
      </div>
    </>
  );
};
