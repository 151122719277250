import Typography from '../typography';
import { getWordCount } from './helpers';
import type { EditorState } from 'draft-js';

interface Props {
  editorState: EditorState;
  wordLimit: number;
}

const WordLimit: React.ComponentType<Props> = ({ editorState, wordLimit }) => {
  const isOverLimit = getWordCount(editorState) > wordLimit;

  return (
    <Typography className={isOverLimit ? 'text-status-red' : 'text-text-grey'}>
      {isOverLimit
        ? 'You are over the word limit.'
        : `${wordLimit} word limit.`}
    </Typography>
  );
};

export default WordLimit;
