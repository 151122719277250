import { forwardRef, ReactElement } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import Tooltip from './tooltip';
import Typography from './typography';

export interface TextInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  containerClassName?: string;
  dark?: boolean;
  description?: string;
  descriptionTextColor?: string;
  error?: boolean;
  helperText?: string | ReactElement;
  helperTextClassName?: string;
  label?: string;
  labelClass?: string;
  labelTextColor?: string;
  leadingIcon?: (props: React.SVGProps<SVGSVGElement>) => ReactElement | null;
  optional?: boolean;
  style?: React.CSSProperties;
  tooltipContent?: string;
  trailingIcon?: (props: React.SVGProps<SVGSVGElement>) => ReactElement | null;
  trailingIconAction?: () => void;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      containerClassName = '',
      dark,
      description,
      descriptionTextColor,
      error,
      helperText,
      helperTextClassName = '',
      id,
      label,
      labelClass = '',
      labelTextColor,
      leadingIcon: LeadingIcon,
      optional,
      style,
      tooltipContent,
      trailingIcon: TrailingIcon,
      trailingIconAction,
      type = 'text',
      ...props
    },
    ref
  ) => {
    return (
      <div className={containerClassName} style={style}>
        {!!label && (
          <div className="mb-1 flex items-center justify-between space-x-2">
            <div>
              <div className="z-10 flex items-center gap-1.5">
                <label htmlFor={id}>
                  <Typography
                    className={clsx(
                      labelClass,
                      'dark:text-white',
                      dark && 'text-white'
                    )}
                    component="span"
                    style={{
                      color: labelTextColor || 'inherit',
                    }}
                    variant="button"
                  >
                    {label}
                  </Typography>
                </label>
                {!!tooltipContent && (
                  <Tooltip
                    hover
                    buttonClassName="flex items-center"
                    content={tooltipContent}
                  />
                )}
              </div>
              {!!description && (
                <Typography
                  style={{
                    color: descriptionTextColor || '#6b7280',
                  }}
                >
                  {description}
                </Typography>
              )}
            </div>

            {optional && (
              <Typography
                className={clsx(
                  'dark:text-white',
                  dark ? 'text-white' : 'text-text-grey'
                )}
              >
                Optional
              </Typography>
            )}
          </div>
        )}
        <div className="relative">
          {LeadingIcon && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <LeadingIcon
                aria-hidden="true"
                className="h-5 w-5 text-gray-400"
              />
            </div>
          )}
          <input
            {...props}
            ref={ref}
            className={clsx(
              'input',
              error && 'input-error pr-11',
              LeadingIcon && 'pl-10',
              TrailingIcon && 'pr-11',
              className
            )}
            id={id}
            type={type}
          />
          {error && !trailingIconAction && (
            <ExclamationCircleIcon className="absolute right-3 top-1/2 h-5 w-5 -translate-y-1/2 text-status-red" />
          )}
          {TrailingIcon && (!error || trailingIconAction) && (
            <div
              className={clsx(
                'absolute inset-y-0 right-0 flex items-center pr-3',
                !trailingIconAction ? 'pointer-events-none' : ''
              )}
            >
              <TrailingIcon
                aria-hidden="true"
                className={clsx(
                  'h-5 w-5 text-gray-400',
                  trailingIconAction ? 'cursor-pointer' : ''
                )}
                onClick={trailingIconAction}
              />
            </div>
          )}
        </div>
        {!!helperText && (
          <Typography
            className={clsx(
              error && 'text-status-red opacity-80',
              dark && 'text-white',
              'mt-0.5 text-sm text-text-grey',
              helperTextClassName
            )}
          >
            {helperText}
          </Typography>
        )}
      </div>
    );
  }
);

export default TextInput;
