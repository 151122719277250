import { useCallback } from 'react';
import { ContentState, EditorState, RichUtils, SelectionState } from 'draft-js';
import {
  FormatIndentIcon,
  FormatOrderedListIcon,
  FormatOutdentIcon,
  FormatUnorderedListIcon,
} from '../icons';
import { useEditor } from './context';

const BLOCK_DATA = [
  { Icon: FormatOrderedListIcon, style: 'ordered-list-item' },
  { Icon: FormatUnorderedListIcon, style: 'unordered-list-item' },
];

/**
 * This function was copied from draft-js repository.
 */
function adjustBlockDepthForContentState(
  contentState: ContentState,
  selectionState: SelectionState,
  adjustment: number,
  maxDepth?: number
): ContentState {
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  let blockMap = contentState.getBlockMap();
  const blocks = blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]])
    .map((block) => {
      // eslint-disable-next-line
      // @ts-ignore
      let depth = block.getDepth() + adjustment;
      depth = Math.max(0, depth);
      if (maxDepth != null) {
        depth = Math.min(depth, maxDepth);
      }

      // eslint-disable-next-line
      // @ts-ignore
      return block.set('depth', depth);
    });

  // eslint-disable-next-line
  // @ts-ignore
  blockMap = blockMap.merge(blocks);

  // eslint-disable-next-line
  // @ts-ignore
  return contentState.merge({
    blockMap,
    selectionAfter: selectionState,
    selectionBefore: selectionState,
  });
}

const ListStyles: React.ComponentType = () => {
  const { editorState, setEditorState } = useEditor();

  const onMouseDown = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.preventDefault();
    },
    []
  );

  const handleIndent = useCallback<
    (adjustment: number) => React.MouseEventHandler<HTMLButtonElement>
  >(
    (adjustment) => {
      return (e) => {
        e.preventDefault();

        setEditorState((prevState) => {
          const content = prevState.getCurrentContent();
          const selection = prevState.getSelection();

          const withAdjustment = adjustBlockDepthForContentState(
            content,
            selection,
            adjustment,
            4
          );

          return EditorState.push(prevState, withAdjustment, 'adjust-depth');
        });
      };
    },
    [setEditorState]
  );

  const toggleBlockType = useCallback<
    (blockType: string) => React.MouseEventHandler<HTMLButtonElement>
  >(
    (blockType) => {
      return (e) => {
        e.preventDefault();

        setEditorState((prevState) =>
          RichUtils.toggleBlockType(prevState, blockType)
        );
      };
    },
    [setEditorState]
  );

  const selection = editorState.getSelection();
  const currentBlockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="flex items-center gap-2" onMouseDown={onMouseDown}>
      {BLOCK_DATA.map((blockType) => (
        <button
          key={blockType.style}
          type="button"
          onClick={toggleBlockType(blockType.style)}
        >
          <blockType.Icon
            className={
              currentBlockType === blockType.style
                ? 'text-text-main'
                : 'text-text-grey'
            }
          />
        </button>
      ))}
      <button type="button" onClick={handleIndent(-1)}>
        <FormatOutdentIcon className="text-text-grey" />
      </button>
      <button type="button" onClick={handleIndent(1)}>
        <FormatIndentIcon className="text-text-grey" />
      </button>
    </div>
  );
};

export default ListStyles;
