import clsx from 'clsx';
import Typography from './typography';

export interface BadgeProps {
  state?: 'positive' | 'neutral' | 'negative';
  title: string;
  variant?: 'primary' | 'secondary';
}

export const Badge: React.ComponentType<BadgeProps> = ({
  state,
  title,
  variant = 'primary',
}) => {
  return (
    <div
      className={clsx(
        !state ? 'bg-secondary-grey-light' : '',
        state === 'positive' && variant === 'primary' ? 'bg-status-green' : '',
        state === 'neutral' && variant === 'primary' ? 'bg-status-orange' : '',
        state === 'negative' && variant === 'primary' ? 'bg-status-red' : '',
        state === 'positive' && variant === 'secondary'
          ? 'border border-chart-green-dark bg-chart-green-light-2'
          : '',
        state === 'neutral' && variant === 'secondary'
          ? 'border border-chart-orange-dark bg-chart-orange-light-2'
          : '',
        state === 'negative' && variant === 'secondary'
          ? 'border border-chart-red-dark bg-chart-red-light-2'
          : '',
        'inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium'
      )}
    >
      <Typography
        className={clsx(
          !state ? 'text-hubs-fixed-dark' : '',
          state && variant === 'primary' ? 'text-white' : '',
          state === 'positive' && variant === 'secondary'
            ? 'text-chart-green-dark'
            : '',
          state === 'neutral' && variant === 'secondary'
            ? 'text-chart-orange-dark'
            : '',
          state === 'negative' && variant === 'secondary'
            ? 'text-chart-red-dark'
            : ''
        )}
        component="span"
        variant="badge"
      >
        {title}
      </Typography>
    </div>
  );
};

export default Badge;
