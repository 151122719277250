export const FormatOutdentIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      fill="currentColor"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.25 9L4.5 11.25V6.75L2.25 9ZM2.25 14.25H15.75V12.75H2.25V14.25ZM2.25 5.25H15.75V3.75H2.25V5.25ZM6.75 8.25H15.75V6.75H6.75V8.25ZM6.75 11.25H15.75V9.75H6.75V11.25Z" />
    </svg>
  );
};

export default FormatOutdentIcon;
