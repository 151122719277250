import { SVGProps } from 'react';

export const PresentationIcon: React.FC<SVGProps<SVGElement>> = () => {
  return (
    <svg
      fill="none"
      height="64"
      viewBox="0 0 64 64"
      width="64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1539_27301)">
        <path
          d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary-text)' }}
        />
        <path
          d="M64 32C64 28.4858 63.4335 25.1038 62.3867 21.9404L54.375 13.9286L9.625 20.1174L32.501 42.9934L11.642 46.3435L29.1755 63.8771C30.1063 63.9584 31.0483 64 32 64C49.6731 64 64 49.6731 64 32Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary-text)' }}
        />
        <path
          d="M34.2895 48.6444V44.6344H29.7106V48.6444C27.45 49.5544 25.8501 51.7699 25.8501 54.3522C25.8501 57.7432 28.609 60.5021 32 60.5021C35.391 60.5021 38.1498 57.7432 38.1498 54.3522C38.1498 51.7699 36.55 49.5544 34.2895 48.6444ZM32 56.7521C30.6767 56.7521 29.6001 55.6755 29.6001 54.3522C29.6001 53.029 30.6767 51.9524 32 51.9524C33.3232 51.9524 34.3998 53.029 34.3998 54.3522C34.3998 55.6755 33.3232 56.7521 32 56.7521Z"
          fill="#E3E3E3"
        />
        <path
          d="M34.2896 48.6447V44.6344H32.0151V48.2028V51.1095V51.9531C33.3315 51.9614 34.4 53.034 34.4 54.3522C34.4 55.6705 33.3315 56.7431 32.0151 56.7514V60.5018C35.3993 60.4935 38.15 57.7382 38.15 54.3522C38.15 51.77 36.5501 49.5546 34.2896 48.6447Z"
          fill="#A7A7A7"
        />
        <path
          d="M11.6421 20.1172H52.358V46.3435H11.6421V20.1172Z"
          fill="#F9FAFB"
        />
        <path
          d="M32.0151 20.1172H52.358V46.3435H32.0151V20.1172Z"
          fill="#E3E3E3"
        />
        <path
          d="M9.625 13.9285H54.375V20.1173H9.625V13.9285Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary-text)' }}
        />
        <path
          d="M32.0151 13.9286H54.3751V20.1174H32.0151V13.9286Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary-text)' }}
        />
        <path
          d="M34.2588 26.063H48.4129V29.8924H34.2588V26.063Z"
          fill="#A7A7A7"
        />
        <path
          d="M34.2588 36.5685H48.4129V40.3979H34.2588V36.5685Z"
          fill="#A7A7A7"
        />
        <path
          d="M34.2588 31.3158H48.4129V35.1451H34.2588V31.3158Z"
          fill="#A7A7A7"
        />
        <path
          d="M25.6089 26.063H20.408L14.4824 35.6855H19.1418L15.5562 43.5433L26.4688 32.1461H21.4002L25.6089 26.063Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary-text)' }}
        />
      </g>
      <defs>
        <clipPath id="clip0_1539_27301">
          <rect fill="white" height="64" width="64" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PresentationIcon;
