import { SVGProps } from 'react';

export const MegaphoneIcon: React.FC<SVGProps<SVGElement>> = () => {
  return (
    <svg
      fill="none"
      height="64"
      viewBox="0 0 65 64"
      width="65"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1539_27299)">
        <path
          d="M32.6001 64C50.2732 64 64.6001 49.6731 64.6001 32C64.6001 14.3269 50.2732 0 32.6001 0C14.927 0 0.600098 14.3269 0.600098 32C0.600098 49.6731 14.927 64 32.6001 64Z"
          fill="currentColor"
          style={{ color: 'var(--company-accent)' }}
        />
        <path
          d="M64.6003 32C64.6003 31.8541 64.5967 31.7093 64.5948 31.5639L55.0377 21.941L54.9914 22.0125L48.4509 15.5074L46.0373 19.5489L38.9385 12.4501L35.8384 24.8961L12.082 43.9703L32.1055 63.9938C32.2704 63.9963 32.4349 64 32.6003 64C50.2734 64 64.6003 49.6731 64.6003 32Z"
          fill="currentColor"
          style={{ color: 'var(--company-accent)' }}
        />
        <path
          d="M33.2232 50.8377L25.3609 53.4912L19.8291 37.6786L27.6916 35.025L33.2232 50.8377Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary)' }}
        />
        <path
          d="M49.4819 42.5886C49.4819 42.5886 30.9859 37.4925 18.506 41.8582L15.1729 32.3304C27.6527 27.9646 38.9385 12.45 38.9385 12.45L49.4819 42.5886Z"
          fill="white"
        />
        <path
          d="M17.1943 38.109L18.506 41.8584C30.986 37.4925 49.482 42.5887 49.482 42.5887L44.1526 27.3544L17.1943 38.109Z"
          fill="#E3E3E3"
        />
        <path
          d="M38.9385 12.45C38.9385 12.45 37.5263 14.3908 35.1504 17.0914L43.6265 41.3208C47.1678 41.9515 49.482 42.5886 49.482 42.5886L38.9385 12.45Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary)' }}
        />
        <path
          d="M39.4033 29.2489L43.6264 41.3208C47.1677 41.9515 49.4819 42.5886 49.4819 42.5886L44.1526 27.3542L39.4033 29.2489Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary)' }}
        />
        <path
          d="M12.082 43.9702L18.506 41.8584L15.1729 32.3305L8.79102 34.563L12.082 43.9702Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary)' }}
        />
        <path
          d="M10.9087 40.6166L12.0819 43.9702L18.5059 41.8584L17.1943 38.109L10.9087 40.6166Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary)' }}
        />
        <path
          d="M46.7864 25.44L47.7627 27.7415L56.0124 24.2422L55.0362 21.9407L46.7864 25.44Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary-text)' }}
        />
        <path
          d="M56.0137 24.2424L55.4652 22.9494L47.1904 26.3909L47.7637 27.7424L56.0137 24.2424Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary-text)' }}
        />
        <path
          d="M43.2668 21.1439L45.5586 22.1429L48.4513 15.5069L46.1595 14.5079L43.2668 21.1439Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary-text)' }}
        />
        <path
          d="M48.195 33.9021L55.231 35.6017L55.8179 33.1719L48.782 31.4722L48.195 33.9021Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary-text)' }}
        />
      </g>
      <defs>
        <clipPath id="clip0_1539_27299">
          <rect
            fill="white"
            height="64"
            transform="translate(0.600098)"
            width="64"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MegaphoneIcon;
