import {
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
  SVGProps,
  useState,
} from 'react';

export function countTwitterCharacters(input: string) {
  // Regular expression to match URLs
  const urlPattern =
    /((https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})([^\s]*))/g;

  const urls = input.match(urlPattern);

  // 1 URL is treated as 23 characters by Twitter https://developer.x.com/en/docs/counting-characters
  // The above regex matches URL but might include trailing characters like `.,;:!` i.e. `https://www.example.com,`
  // Those characters need to be counted separately and should not be considered as part of the URL
  const extraLength = urls
    ? urls.reduce(
        (acc, url) =>
          acc + 23 + (url.length - url.replace(/[.,:;!]+$/g, '').length),
        0
      )
    : 0;

  // Replace each URL to an empty string (0 character)
  const modifiedInput = input.replace(urlPattern, '');

  // Calculate total length: sum of modified string length + URL placeholders
  return modifiedInput.length + extraLength;
}

export function getRefinitivSymbolFromListingKeyAndMarketKey(
  listingKey: string,
  marketKey: string
) {
  const input = `${listingKey}`.trim().toUpperCase();

  switch (marketKey.toLowerCase()) {
    case 'aqse':
      return `${input}.ASE`;
    case 'lse':
      return `${input}.L`;
    default:
      return `${input}.AX`;
  }
}

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export type AutoSaveable<T> = string | number | Record<string, unknown> | T[];

export const storageSafeValue = <T>(value: AutoSaveable<T>): string =>
  typeof value === 'object' ? JSON.stringify(value) : `${value}`;

// We use this function to clear all the localStorage data for a given set of keys
export const clearAutoSaveStates = (...storageKeys: string[]) =>
  storageKeys.forEach((key) => localStorage.removeItem(key));

/**
 * A helpful variant of useState which persists changes to the defined variable to
 * a key in localStorage such that it can be recovered if the page is lost.
 *
 * In the case where no value has yet been saved, useAutoSaveState will return the
 * value passed in on initialisation. If there is a value behind the provided storageKey,
 * we'll return that instead. This means it's important to determine scenarios in which you
 * want to preference the initialisation value, and use clearAutoSaveStates at certain checkpoints
 * accordingly.
 *
 * For example, if somebody saves a draft of something in progress to the database, you should
 * clear the associated localStorage and fall back to the initialisation value from the database
 * until further changes are made.
 */
export const useAutoSaveState = <T>(
  initialState: AutoSaveable<T>,
  storageKey: string
): [string, (newValue: AutoSaveable<T>) => void] => {
  const savedValue = localStorage.getItem(storageKey);
  const nothingSavedYet = savedValue == null;
  const initialValue = nothingSavedYet ? initialState : savedValue;

  const [value, setValue] = useState<string>(storageSafeValue(initialValue));

  const setAutoSaveValue = (newValue: AutoSaveable<T>): void => {
    const safeVal = storageSafeValue(newValue);
    localStorage.setItem(storageKey, safeVal);
    setValue(safeVal);
  };

  return [value, setAutoSaveValue];
};

export type HeroiconV2Type = ForwardRefExoticComponent<
  PropsWithoutRef<SVGProps<SVGSVGElement>> & {
    title?: string;
    titleId?: string;
  } & RefAttributes<SVGSVGElement>
>;

export const truncate = (str: string, amount: number): string => {
  if (str.length > amount) {
    return str.slice(0, amount) + '...';
  } else {
    return str;
  }
};
