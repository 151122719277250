export const PinIcon: React.ComponentType<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Group">
        <path
          d="M20.8293 4.40782L16.591 0.172495C16.361 -0.0574982 15.9868 -0.0574982 15.7568 0.172495L15.2691 0.660142C15.0391 0.890175 15.0391 1.26433 15.2691 1.49432L15.4654 1.6906L10.6321 6.52699C9.46672 5.88909 7.97928 6.06083 6.9948 7.04526L5.25284 8.78723C5.02284 9.01722 5.02284 9.39138 5.25284 9.62141L7.39045 11.759L0.158835 19.7512C-0.0589303 19.9935 -0.0527565 20.3768 0.177236 20.6069L0.388828 20.8185C0.618862 21.0485 1.0022 21.0608 1.2445 20.8369L9.23055 13.6145L11.3497 15.7337C11.5797 15.9637 11.9539 15.9637 12.1839 15.7337L13.9259 13.9917C14.9073 13.0103 15.0851 11.5382 14.4595 10.3728L19.296 5.53642L19.4922 5.7327C19.7222 5.96269 20.0964 5.96269 20.3265 5.7327L20.8141 5.24505C21.0594 5.01506 21.0594 4.6409 20.8293 4.40782Z"
          fill="currentColor"
          id="Vector"
        />
      </g>
    </svg>
  );
};

export default PinIcon;
