export interface CircularProgressProps {
  percentage?: number;
  size?: number;
}

export const CircularProgress: React.ComponentType<CircularProgressProps> = ({
  percentage = 0,
  size = 32,
}) => {
  const strokeWidth = size / 8;
  const radius = (size - strokeWidth) / 2;
  const strokeDasharray = radius * Math.PI * 2;
  const strokeDashoffset =
    strokeDasharray - (strokeDasharray * percentage) / 100;

  if (percentage < 100) {
    return (
      <svg height={size} viewBox={`0 0 ${size} ${size}`} width={size}>
        <circle
          className="fill-transparent stroke-secondary-grey-light"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className="fill-transparent stroke-status-green"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </svg>
    );
  }

  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" fill="#00A370" r="16" />
      <path
        d="M22.0421 11.4392C22.3233 11.7205 22.4812 12.1019 22.4812 12.4997C22.4812 12.8974 22.3233 13.2789 22.0421 13.5602L14.5421 21.0602C14.2608 21.3414 13.8793 21.4994 13.4816 21.4994C13.0838 21.4994 12.7024 21.3414 12.4211 21.0602L9.42107 18.0602C9.14783 17.7773 8.99664 17.3984 9.00006 17.0051C9.00347 16.6118 9.16123 16.2356 9.43934 15.9575C9.71745 15.6793 10.0937 15.5216 10.487 15.5182C10.8803 15.5148 11.2592 15.6659 11.5421 15.9392L13.4816 17.8787L19.9211 11.4392C20.2024 11.158 20.5838 11 20.9816 11C21.3793 11 21.7608 11.158 22.0421 11.4392Z"
        fill="white"
      />
    </svg>
  );
};

export default CircularProgress;
