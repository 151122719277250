import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import analytics from '@analytics';
import {
  ApolloClient,
  NetworkStatus,
  NormalizedCacheObject,
} from '@apollo/client';
import { XMarkIcon } from '@heroicons/react-v2/24/outline';
import {
  ChatIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  SearchIcon,
  ThumbUpIcon,
  MenuAlt2Icon,
  VideoCameraIcon,
} from '@heroicons/react/outline';
import dayjs from 'dayjs';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useAsyncDebounce } from 'react-table';
import {
  Select,
  Skeleton,
  Typography,
  Tooltip,
  TextInput,
  Pagination,
  calculateStartCursor,
  BadgeV2,
  FilterInput,
  StartCursor,
  useMediaAnnouncementsQuery,
  BlockHeading,
  Button,
  useTotalFeaturedAnnouncementsLazyQuery,
} from '../../../index';
import { QuickFilters } from './quick-filters';
import { QuickSort } from './quick-sort';

export type QuickFilterType = { key: string; value: string | boolean | Date };
export type QuickFilterWithLabel = QuickFilterType & { label: string };

interface Props {
  client?: ApolloClient<NormalizedCacheObject>;
  featuresEnabled: (string | null)[];
  heading?: string;
  hub: string;
  isUK: boolean;
  listingKey: string;
  marketKey: string;
  tags?: string[];
  translate: (key: string) => string;
}

export const AnnouncementsBlock: React.ComponentType<Props> = ({
  client,
  featuresEnabled,
  heading,
  hub,
  isUK,
  listingKey,
  marketKey,
  tags,
  translate,
}) => {
  const router = useRouter();
  const { query } = router;
  const {
    announcementOrder: order,
    announcementPage: page,
    announcementRows: rows,
  } = query;

  const [totalCount, setTotalCount] = useState(0);

  const [orders, setOrders] = useState([{ key: 'posted_at', value: 'desc' }]);
  const [startCursor, setStartCursor] = useState(StartCursor);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [searchFieldValue, setSearchFieldValue] = useState(searchPhrase);

  const onChange = useAsyncDebounce((value) => {
    setSearchPhrase(value);
    analytics.track('hermes_sort_or_filter', {
      feature: 'announcements',
      filter: 'search',
      hubs_version: '2',
    });
  }, 350);

  const originalFilters = useMemo(
    () => (tags?.length ? [{ key: 'tags', value: tags.join(',') }] : []),
    [tags]
  );

  const [appliedFilters, setAppliedFilters] =
    useState<QuickFilterType[]>(originalFilters);

  const getFilters = useCallback(() => {
    const filters = [
      ...appliedFilters
        .map((filter) => {
          if (filter.key === 'startDate') {
            return filter.value
              ? {
                  key: 'posted_at_greater_than',
                  value: dayjs(filter.value as Date).toISOString(),
                }
              : null;
          }
          if (filter.key === 'endDate') {
            return filter.value
              ? {
                  key: 'posted_at_less_than',
                  value: dayjs(filter.value as Date).toISOString(),
                }
              : null;
          }
          return filter;
        })
        .filter((f) => !!f && f.key !== 'tags'),
      { key: 'market_key', value: marketKey },
      { key: 'ticker', value: listingKey },
      { key: 'tags', value: (tags || []).join(',') },
    ];

    if (searchPhrase) {
      filters.push({ key: 'search', value: searchPhrase });
    }

    return filters as FilterInput[];
  }, [appliedFilters, marketKey, listingKey, searchPhrase, tags]);

  const { data, loading, networkStatus } = useMediaAnnouncementsQuery({
    client,
    fetchPolicy: 'no-cache',
    variables: {
      after: startCursor,
      first: rowsPerPage,
      hub,
      options: {
        filters: getFilters(),
        orders,
      },
    },
  });

  const [fetchTotal] = useTotalFeaturedAnnouncementsLazyQuery({
    client,
    fetchPolicy: 'no-cache',
    variables: { hub },
  });

  useEffect(() => {
    // Only make this call if the feature is enabled, let's not make unnecessary calls
    if (featuresEnabled.includes('featured_announcements')) {
      fetchTotal().then((result) => {
        if (result?.data?.totalFeaturedAnnouncements || 0 >= 1) {
          setAppliedFilters((prev) => [
            ...prev,
            { key: 'featured', value: 'true' },
          ]);
        }
      });
    }
    // This effect should only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showLanguages = featuresEnabled.includes(
    'german_announcement_translations'
  );

  const rowSelectRef = useRef<HTMLTableElement>(null);

  const { host, ...queryWithoutHost } = query;

  const pathname = router.pathname.replace('/_companies/[host]', '/');

  useEffect(() => {
    // rows per page
    if (rows) {
      const per = rows as string;
      const intPer = parseInt(per);
      if ([10, 20, 50].includes(intPer) && intPer !== rowsPerPage) {
        setRowsPerPage(intPer);
      }
    }
    // page number
    if (page) {
      const p = page as string;
      const intP = parseInt(p);
      if (intP !== currentPage) {
        setCurrentPage(intP);
      }
    }
    // order
    if (order) {
      const o = order as string;
      if (
        ['posted_at', 'questions', 'likes', 'engagement'].includes(o) &&
        o !== orders[0].key
      ) {
        setOrders([{ key: o, value: 'desc' }]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const setRows = (rows: number) => {
    router
      .push(
        {
          hash: 'announcements_block',
          pathname,
          query: {
            ...queryWithoutHost,
            ...{ announcementPage: 1, announcementRows: rows },
          },
        },
        undefined,
        { shallow: true }
      )
      .then(() => {
        if (rows < rowsPerPage) {
          rowSelectRef.current?.scrollIntoView();
        }
      });
  };

  const handlePageChange = (page: number) => {
    router
      .push(
        {
          hash: 'announcements_block',
          pathname,
          query: { ...queryWithoutHost, ...{ announcementPage: page } },
        },
        undefined,
        { shallow: true }
      )
      .then(() => {
        rowSelectRef.current?.scrollIntoView();
      });
  };

  const setOrder = (order: string) => {
    router.push(
      {
        hash: 'announcements_block',
        pathname,
        query: { ...queryWithoutHost, ...{ announcementOrder: order } },
      },
      undefined,
      { shallow: true }
    );
  };

  useEffect(() => {
    calculateStartCursor(currentPage, rowsPerPage, setStartCursor);
  }, [currentPage, rowsPerPage, setStartCursor]);

  // Set page to be last page if changing rows per page and the currentPage is out of range
  useEffect(() => {
    if (
      !!data?.mediaAnnouncements?.total &&
      currentPage > data?.mediaAnnouncements?.total / rowsPerPage
    ) {
      setCurrentPage(Math.ceil(data?.mediaAnnouncements?.total / rowsPerPage));
    }
  }, [
    currentPage,
    data?.mediaAnnouncements?.total,
    rowsPerPage,
    setCurrentPage,
  ]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.ready)
      setTotalCount(data?.mediaAnnouncements?.total ?? 0);
  }, [data, networkStatus]);

  function renderEmptyState() {
    return (
      <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
        <Typography className="text-hubs-secondary">
          <em>{`No ${translate(
            'announcements.lowercase'
          )} found for ${marketKey}:${listingKey}.`}</em>
        </Typography>
        <div>
          {appliedFilters.length > 0 || searchPhrase.length ? (
            <Button
              size="md"
              startIcon={<XMarkIcon className="h-5 w-5" />}
              variant="secondary"
              onClick={() => {
                setAppliedFilters(originalFilters);
                setSearchPhrase('');
              }}
            >
              Clear search & filters
            </Button>
          ) : null}
        </div>
      </div>
    );
  }

  function renderList() {
    if (loading) {
      return (
        <>
          {Array.from(new Array(rowsPerPage).keys()).map((item) => (
            <Skeleton key={item} loading height={60} variant="rect" />
          ))}
        </>
      );
    }

    if (data?.mediaAnnouncements) {
      const edges = data.mediaAnnouncements.edges;
      if (edges && edges.length === 0) {
        return <>{renderEmptyState()}</>;
      }

      return (edges || []).map((edge) => {
        if (edge?.node) {
          const hasGermanTranslation =
            edge.node.germanTranslatedHeader ||
            edge.node.germanTranslatedSummary ||
            edge.node.germanTranslatedUrl ||
            edge.node.germanTranslatedVideoUrl;

          return (
            <Link
              key={edge.node.id}
              className="block border-b py-4"
              href={`/announcements/${edge.node.id}`}
            >
              <div className="flex flex-col items-start gap-1.5">
                <div className="flex w-full flex-row items-center justify-between">
                  <div className="flex flex-col items-start gap-2">
                    <Typography
                      className="flex gap-2 text-hubs-primary"
                      component="div"
                      variant="body-small"
                    >
                      <div>
                        {dayjs(edge.node.postedAt).format(
                          'DD/MM/YYYY • hh:mm A'
                        )}
                      </div>
                    </Typography>
                    {showLanguages && (
                      <div className="-my-1.5 text-lg tracking-[8px]">
                        <span aria-label="English" role="img">
                          🇬🇧
                        </span>
                        {hasGermanTranslation && (
                          <span aria-label="German" role="img">
                            🇩🇪
                          </span>
                        )}
                      </div>
                    )}
                    <Typography
                      className="line-clamp-2 text-company-primary"
                      variant="button"
                    >
                      {edge.node.header}
                    </Typography>
                  </div>
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                </div>
                <div className="mt-1 flex w-full flex-row items-center justify-between">
                  <div className="flex flex-row gap-1.5">
                    {featuresEnabled.includes('featured_announcements') &&
                      edge.node.featuredOnHub && (
                        <BadgeV2 color="green">Featured</BadgeV2>
                      )}
                    {isUK && edge.node.newsPublisher && (
                      <div className="w-fit rounded-xl border border-company-primary px-1.5 pb-0.5">
                        <Typography
                          className="text-company-primary"
                          variant="badge"
                        >
                          {edge.node.newsPublisher}
                        </Typography>
                      </div>
                    )}
                    {!!(edge.node.socialVideoUrl || edge.node.videoUrl) && (
                      <Tooltip
                        hover
                        content={`This announcement has a video uploaded by ${marketKey}:${listingKey}`}
                      >
                        <VideoCameraIcon className="h-5 w-5 text-hubs-secondary" />
                      </Tooltip>
                    )}
                    {!!edge.node.summary && (
                      <Tooltip
                        hover
                        content={`This announcement has a summary added by ${marketKey}:${listingKey}`}
                      >
                        <MenuAlt2Icon className="h-5 w-5 text-hubs-secondary" />
                      </Tooltip>
                    )}
                    {!!edge.node.marketSensitive && (
                      <Tooltip
                        hover
                        content={`This announcement is price sensitive`}
                      >
                        <CurrencyDollarIcon className="h-5 w-5 text-hubs-secondary" />
                      </Tooltip>
                    )}
                  </div>
                  <div className="mr-8 flex items-center gap-4">
                    {edge.node.totalParentComments > 0 && (
                      <div className="typography-body-regular flex items-center gap-2 text-hubs-secondary">
                        <ChatIcon className="h-5 w-5" />
                        {edge.node.totalParentComments}
                      </div>
                    )}
                    {edge.node.likes > 0 && (
                      <div className="typography-body-regular flex items-center gap-2 text-hubs-secondary">
                        <ThumbUpIcon className="h-5 w-5" />
                        {edge.node.likes}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Link>
          );
        }

        return null;
      });
    }
    return null;
  }

  function renderTableBody() {
    if (loading) {
      return (
        <>
          {Array.from(new Array(rowsPerPage).keys()).map((item) => (
            <tr key={item}>
              <td className="px-5 py-3">
                <Skeleton
                  loading
                  fontSize="body-regular"
                  variant="text"
                  width="70%"
                />
              </td>
              <td className="py-3">
                <Skeleton
                  loading
                  fontSize="button"
                  variant="text"
                  width="70%"
                />
              </td>
              <td className="py-3">
                <div className="flex items-center">
                  <div className="h-6" />
                  <Skeleton
                    loading
                    fontSize="body-regular"
                    variant="text"
                    width="70%"
                  />
                </div>
              </td>
              <td className="py-3">
                <div className="flex items-center">
                  <div className="h-6" />
                  <Skeleton
                    loading
                    fontSize="body-regular"
                    variant="text"
                    width="70%"
                  />
                </div>
              </td>
            </tr>
          ))}
        </>
      );
    }

    if (data?.mediaAnnouncements) {
      if (data.mediaAnnouncements.edges?.length === 0) {
        return (
          <tr>
            <td
              className="typography-body-regular px-5 py-3 text-hubs-primary"
              colSpan={4}
            >
              {renderEmptyState()}
            </td>
          </tr>
        );
      }

      return data.mediaAnnouncements.edges?.map((edge) => {
        if (edge?.node) {
          const hasGermanTranslation =
            edge.node.germanTranslatedHeader ||
            edge.node.germanTranslatedSummary ||
            edge.node.germanTranslatedUrl ||
            edge.node.germanTranslatedVideoUrl;
          return (
            <tr
              key={edge.node.id}
              className="group bg-hubs-background px-4 transition-colors hover:bg-hubs-background-accent"
            >
              <td
                className="typography-body-regular whitespace-nowrap py-3 pl-5 text-hubs-primary"
                valign="top"
              >
                <div className="flex flex-col gap-0.5">
                  <p className="text-wrap text-sm font-medium transition-colors">
                    {dayjs(edge.node.postedAt).format('DD/MM/YYYY')}
                  </p>
                  <time className="text-sm text-hubs-secondary">
                    {dayjs(edge.node.postedAt).format('hh:mm A')}
                  </time>
                </div>
              </td>
              {showLanguages && (
                <td
                  className="typography-body-regular whitespace-nowrap py-3 text-xl tracking-[10px] text-hubs-primary"
                  valign="top"
                >
                  <span aria-label="English" role="img">
                    🇬🇧
                  </span>
                  {hasGermanTranslation && (
                    <span aria-label="German" role="img">
                      🇩🇪
                    </span>
                  )}
                </td>
              )}
              <td
                className="typography-button w-full whitespace-nowrap py-3"
                valign="top"
              >
                <Link
                  className="flex flex-col gap-1"
                  href={`/announcements/${edge.node.id}`}
                >
                  <p className="line-clamp-1 text-wrap pr-4 text-sm text-company-primary">
                    {edge.node.header}
                  </p>
                  <div className="flex w-full flex-row justify-between">
                    <div className="flex flex-row items-center gap-1">
                      {isUK && edge.node.newsPublisher && (
                        <div className="w-fit rounded-xl border px-2 text-hubs-secondary">
                          <Typography className="text-xs" variant="badge">
                            {edge.node.newsPublisher}
                          </Typography>
                        </div>
                      )}
                      {featuresEnabled.includes('featured_announcements') &&
                        edge.node.featuredOnHub && (
                          <BadgeV2 color="green">Featured</BadgeV2>
                        )}
                      {!!edge.node.marketSensitive && (
                        <Tooltip
                          hover
                          content={`This announcement is price sensitive`}
                        >
                          <CurrencyDollarIcon className="size-5 stroke-[1.5] text-hubs-secondary" />
                        </Tooltip>
                      )}
                      {!!(edge.node.socialVideoUrl || edge.node.videoUrl) && (
                        <Tooltip
                          hover
                          content={`This announcement has a video uploaded by ${marketKey}:${listingKey}`}
                        >
                          <VideoCameraIcon className="size-5 stroke-[1.5] text-hubs-secondary" />
                        </Tooltip>
                      )}
                      {!!edge.node.summary && (
                        <Tooltip
                          hover
                          content={`This announcement has a summary added by ${marketKey}:${listingKey}`}
                        >
                          <MenuAlt2Icon className="size-5 stroke-[1.5] text-hubs-secondary" />
                        </Tooltip>
                      )}
                    </div>
                    <div className="flex flex-row items-center justify-center gap-4 pr-6">
                      {edge.node.totalParentComments > 0 && (
                        <Link
                          passHref
                          className="typography-body-regular flex items-center gap-1.5 text-hubs-secondary"
                          href={`/announcements/${edge.node.id}#question-section`}
                          scroll={false}
                        >
                          <ChatIcon className="size-5" />
                          {edge.node.totalParentComments}
                        </Link>
                      )}

                      {edge.node.likes > 0 && (
                        <div className="typography-body-regular flex items-center gap-1.5 text-hubs-secondary">
                          <ThumbUpIcon className="size-5" />
                          {edge.node.likes}
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              </td>
            </tr>
          );
        }

        return null;
      });
    }

    return (
      <tr>
        <td
          className="typography-body-regular px-5 py-3 font-body text-hubs-secondary"
          colSpan={4}
        >
          <em>Oops! Something went wrong.</em>
        </td>
      </tr>
    );
  }

  return (
    <div className="relative scroll-mt-40" id="announcements_block">
      <span
        ref={rowSelectRef}
        className="absolute inset-x-0 top-0 h-0 w-full"
      />
      <div className="w-full max-w-screen-xl px-4 sm:px-6 lg:mx-auto">
        {heading && heading.length > 0 && (
          <BlockHeading className="mb-4">{heading}</BlockHeading>
        )}
        <div className="mb-4 flex flex-col gap-6">
          <div className="flex w-full items-center justify-between gap-4 md:w-auto md:flex-row md:flex-wrap md:gap-6">
            <QuickFilters
              appliedFilters={appliedFilters}
              isFeatured={featuresEnabled.includes('featured_announcements')}
              isUK={isUK}
              setAppliedFilters={setAppliedFilters}
              tags={tags}
            />
            <QuickSort orders={orders} setOrder={setOrder} />
          </div>

          <TextInput
            className="min-h-[48px] rounded-lg py-2.5"
            leadingIcon={SearchIcon}
            placeholder={`Search ${translate(
              'announcement.lowercase'
            )} title...`}
            style={{ flexGrow: 1 }}
            value={searchFieldValue}
            onChange={(e) => {
              setSearchFieldValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </div>

        <div className="mt-6 lg:hidden">
          <div>{renderList()}</div>

          <div className="mt-4 flex flex-row items-center justify-between gap-4 md:gap-10">
            <Select
              className="font-system shadow-none"
              label=""
              options={[
                { label: '10', value: 10 },
                { label: '20', value: 20 },
                { label: '50', value: 50 },
              ]}
              value={rowsPerPage}
              onChange={setRows}
            />
            <Pagination
              compact
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              setCurrentPage={handlePageChange}
              totalCount={totalCount}
            />
          </div>
        </div>

        <div className="hidden lg:block">
          <div className="overflow-hidden rounded-lg border">
            <table className="w-full table-fixed divide-y">
              <thead className="bg-hubs-background-accent">
                <tr>
                  <th className="w-40 gap-2 whitespace-nowrap bg-hubs-background-accent py-3 pl-5 text-left font-body text-xs uppercase !tracking-widest text-hubs-secondary">
                    Date
                  </th>
                  {showLanguages && (
                    <th className="w-32 gap-2 whitespace-nowrap bg-hubs-background-accent py-3  text-left font-body text-xs uppercase !tracking-widest text-hubs-secondary">
                      Language
                    </th>
                  )}
                  <th className="w-full whitespace-nowrap bg-hubs-background-accent py-3 text-left font-body text-xs uppercase !tracking-widest text-hubs-secondary">
                    Headline
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y">{renderTableBody()}</tbody>
            </table>
          </div>

          <div className="mt-4 flex w-full items-center gap-6">
            <div className="z-10 col-span-1">
              <Select
                className="font-system shadow-none"
                label=""
                options={[
                  { label: '10', value: 10 },
                  { label: '20', value: 20 },
                  { label: '50', value: 50 },
                ]}
                value={rowsPerPage}
                onChange={setRows}
              />
            </div>
            <div className="flex-1">
              <Pagination
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                setCurrentPage={handlePageChange}
                totalCount={totalCount}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
