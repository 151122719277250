import { ComponentType, useEffect, useState } from 'react';
import analytics from '@analytics';
import ipRangeCheck from 'ip-range-check';
import LogRocket from 'logrocket';
import { useCookies } from 'react-cookie';
import useSWR from 'swr';
import { getCookieConsent } from '@leaf/helpers';
import {
  CurrentCompanyQuery,
  useCreateTrackingEventMutation,
  useCurrentInvestorUserQuery,
} from '@/apollo/generated';
import fetcher, { fetchOptions } from '@/utils/refinitiv/fetcher';

const Analytics: ComponentType<{
  children?: React.ReactNode;
  currentCompany?: NonNullable<CurrentCompanyQuery['currentCompany']>;
}> = ({ children, currentCompany }) => {
  const { data } = useCurrentInvestorUserQuery();
  const [hasTrackedReferrer, setHasTrackedReferrer] = useState(false);
  const [createTrackingEvent] = useCreateTrackingEventMutation();
  const cookieConsent = getCookieConsent();
  const [{ _hermes_segment_token }, ,] = useCookies();

  const [identifiedBy, setIdentifiedBy] = useState<string | null>(null);

  const { data: ipData } = useSWR<{
    crawlerIps: string[];
    currentIp: string;
    userAgent: string;
  }>('/api/web-crawler-ips', fetcher, fetchOptions);

  const crawler = (
    ip: string | undefined,
    ranges: string[] | undefined,
    userAgent: string | undefined
  ) =>
    ranges && ip
      ? ipRangeCheck(ip, ranges)
      : userAgent
      ? userAgent.includes('BLP_bbot')
      : false;

  const identifyUser = () => {
    // Crawlers so seeming to be identified below this..., so try to identify them as crawler
    let isCrawler = crawler(
      ipData?.currentIp,
      ipData?.crawlerIps,
      ipData?.userAgent
    );

    // Commenting this lines since mixpanel getting hammered, will come back to this later when we figure out a better way to handle this
    // if (isCrawler) {
    //   analytics.identify(ipData?.currentIp ?? 'crawler', {
    //     isCrawler,
    //   });
    // }

    // Don't try to identify if we don't have the necessary data or we are running server side
    if (!data || !currentCompany || typeof window === 'undefined') return;
    const featuresEnabled = currentCompany.featuresEnabled
      ? currentCompany.featuresEnabled.sort()
      : [];
    const company = `${currentCompany.ticker?.listingKey}.${currentCompany.ticker?.marketKey}`;
    const isDemo = !!currentCompany.isDemo;
    const isPremium = !!currentCompany.isPremium;
    const isTrial = !!currentCompany.isTrial;
    const isHubs2 = !!currentCompany.publishedWebsite;
    const isFullWebsiteReplacement =
      !!currentCompany.publishedWebsite?.isFullWebsiteReplacement;

    if (_hermes_segment_token && ipData?.crawlerIps) {
      const { crawlerIps, currentIp, userAgent } = ipData;
      isCrawler = crawler(currentIp, crawlerIps, userAgent);
      if (data.currentInvestorUser) {
        const { email, firstName, id, lastName } = data.currentInvestorUser;
        const userName = [firstName, lastName].join(' ').trim();
        // They may already by identified before we got the currentInvestorUser
        if (!!identifiedBy && identifiedBy === `database_id:${id}`) return;
        analytics.identify(id, {
          company,
          email,
          featuresEnabled,
          isCrawler,
          isDemo,
          isFullWebsiteReplacement,
          isHubs2,
          isPremium,
          isTrial,
          name: userName,
        });
        setIdentifiedBy(`database_id:${id}`);
      } else {
        // Don't identify them again
        if (identifiedBy) return;
        analytics.identify(_hermes_segment_token, {
          company,
          featuresEnabled,
          isCrawler,
          isDemo,
          isFullWebsiteReplacement,
          isHubs2,
          isPremium,
          isTrial,
        });
        setIdentifiedBy(_hermes_segment_token);
      }
    }
    if (
      cookieConsent &&
      cookieConsent.analytics &&
      data.currentInvestorUser &&
      process.env.NODE_ENV === 'production'
    ) {
      const { email, firstName, id, lastName } = data.currentInvestorUser;
      const userName = [firstName, lastName].join(' ').trim();

      LogRocket.identify(id, {
        company,
        email,
        isDemo,
        isPremium,
        isTrial,
        name: userName,
      });
    }
  };

  useEffect(() => {
    const sendData = async () => {
      if (!hasTrackedReferrer && window.frames.self.document.referrer) {
        const trackingEvent = {
          event: 'referrer',
          eventMetadata: {
            href: window.location.href,
            pathname: window.location.pathname,
            referrer: window.frames.self.document.referrer,
            search: window.location.search,
          },
        };

        await createTrackingEvent({
          variables: {
            trackingEvent: trackingEvent,
          },
        });

        setHasTrackedReferrer(true);
      }
    };
    sendData();
  }, [createTrackingEvent, hasTrackedReferrer]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(identifyUser, [
    _hermes_segment_token,
    cookieConsent,
    data?.currentInvestorUser,
    currentCompany,
    ipData,
  ]);

  return <>{children}</>;
};

export default Analytics;
