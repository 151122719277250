'use client'; // https://www.blocknotejs.org/docs/advanced/nextjs - can't use blocknote in server side
import React, { useEffect, useState } from 'react';
import { Block } from '@blocknote/core';
import { useCreateBlockNote } from '@blocknote/react';
import draftToHtml from 'draftjs-to-html';
import { LinkedinIcon } from '../../icons';
import Typography from '../../typography';
import { BLOCK_WIDTH_CLASS } from '../constants';
import { ImageMeta } from '../types';
import CloudinaryImage from '../ui/cloudinary-image';
import { emitBlockClickEvent } from '../utils';

export interface WelcomeMessageBlockProps {
  blockId?: string;
  content: {
    company?: string;
    linkedInUrl?: string;
    message?: Block[];
    migratedMessage?: string;
    name?: string;
    profilePicture?: ImageMeta | null;
    showSignature?: boolean;
    signature?: ImageMeta | null;
    title?: string;
  };
}

const PLACEHOLDER_NAME = 'John Doe';
const PLACEHOLDER_TITLE = 'CEO';
const PLACEHOLDER_COMPANY = 'Acme Inc.';
const PLACEHOLDER_MESSAGE =
  'Welcome to our company. We are excited to have you as part of our community.';

export const WelcomeMessageBlock: React.ComponentType<
  WelcomeMessageBlockProps
> = ({ blockId, content }) => {
  const [messageHTML, setMessageHTML] = useState<string>('');
  const editor = useCreateBlockNote();

  useEffect(() => {
    const convertMessageToHTML = async () => {
      if (content.migratedMessage) {
        const html = draftToHtml(JSON.parse(content.migratedMessage));
        setMessageHTML(html);
      } else if (content.message) {
        const html = await editor.blocksToHTMLLossy(content.message);
        setMessageHTML(html);
      } else {
        setMessageHTML(PLACEHOLDER_MESSAGE.replace(/\n/g, '<br />'));
      }
    };

    convertMessageToHTML();
  }, [editor, content.message, content.migratedMessage]);

  return (
    <div
      className={`${BLOCK_WIDTH_CLASS} mx-auto max-w-screen-xl`}
      onClick={() => emitBlockClickEvent(blockId)}
    >
      <div className="flex w-full flex-col items-start gap-8 md:flex-row md:gap-16">
        <div className="flex flex-col items-start gap-6">
          {content.profilePicture && content.profilePicture.cloudinaryUrl ? (
            <CloudinaryImage
              alt={content.name || PLACEHOLDER_NAME}
              className="rounded-lg"
              crop="fill"
              gravity="face"
              height={240}
              src={content.profilePicture.cloudinaryUrl || ''}
              width={240}
            />
          ) : (
            <div className="h-24 w-24 rounded-full bg-gray-200 md:h-60 md:w-60" />
          )}
          <div className="flex flex-col gap-1 border-l-4 border-gray-300 pl-4">
            <Typography
              className="font-body text-lg font-semibold leading-normal tracking-tight text-hubs-primary"
              variant="heading-5"
            >
              {content.name || PLACEHOLDER_NAME}
            </Typography>
            <div className="flex flex-col items-start gap-1">
              <Typography
                className="font-body leading-normal text-gray-600"
                variant="body-regular"
              >
                {content.title || PLACEHOLDER_TITLE}
              </Typography>
              <Typography
                className="text-left font-body text-gray-400"
                variant="body-regular"
              >
                {content.company || PLACEHOLDER_COMPANY}
              </Typography>
            </div>

            {content.linkedInUrl && (
              <a
                className="mt-2 text-gray-400 transition-colors hover:text-gray-500"
                href={content.linkedInUrl}
              >
                <span className="sr-only">LinkedIn</span>
                <LinkedinIcon className="size-5" />
              </a>
            )}
          </div>
        </div>
        {/* AS: my-auto here (instead of using items-center) so it works as both centred when less text, and top aligned when lots of text */}
        <div className="my-auto w-full">
          <div
            dangerouslySetInnerHTML={{ __html: messageHTML }}
            className="blocknote prose mb-8 max-w-none list-inside list-disc font-body text-lg leading-normal text-hubs-primary marker:text-hubs-primary prose-headings:text-hubs-primary prose-p:text-hubs-primary prose-a:text-hubs-primary prose-strong:text-hubs-primary prose-table:text-hubs-primary"
          />
          {content.showSignature && (
            <div>
              {content.signature && content.signature.cloudinaryUrl && (
                <CloudinaryImage
                  alt={content.name || PLACEHOLDER_NAME}
                  className="rounded-lg bg-gray-100"
                  crop="fill"
                  height={100}
                  src={content.signature.cloudinaryUrl || ''}
                  width={250}
                />
              )}
              <div className="flex flex-col items-start gap-1">
                <Typography
                  className="font-body leading-normal text-hubs-primary"
                  variant="body-regular"
                >
                  {content.name || PLACEHOLDER_NAME}
                </Typography>
                <Typography
                  className="font-body leading-normal text-hubs-secondary"
                  variant="body-regular"
                >
                  {content.title || PLACEHOLDER_TITLE}
                </Typography>
                <Typography
                  className="text-left font-body text-hubs-secondary"
                  variant="body-regular"
                >
                  {content.company || PLACEHOLDER_COMPANY}
                </Typography>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
