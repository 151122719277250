import { type Block } from '@blocknote/core';
import { ArrowRightIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { getCldOgImageUrl } from 'next-cloudinary';
import Image from 'next/image';
import Link from 'next/link';
import slugify from 'slugify';
import {
  LinkButton,
  Typography,
  WebinarsQuery,
  RenderBlockNote,
} from '../../../index';

interface Props {
  webinar: NonNullable<
    NonNullable<
      NonNullable<NonNullable<WebinarsQuery['webinars']>['edges']>[0]
    >['node']
  >;
}

export const WebinarBlockCard: React.ComponentType<Props> = ({ webinar }) => {
  function getUrl() {
    const slug = slugify(`${webinar.id}-${webinar.title || ''}`, {
      lower: true,
    });
    return `/webinars/${slug}`;
  }

  return (
    <Link
      className={clsx(
        'group flex flex-col overflow-hidden rounded-lg border bg-hubs-background shadow-none transition-shadow md:hover:shadow-md'
      )}
      href={getUrl()}
    >
      <div
        className={clsx(
          'flex w-full shrink-0 items-center justify-center overflow-hidden bg-company-accent'
        )}
      >
        <div className={clsx('relative h-full w-full pt-[39%]', 'md:pt-[35%]')}>
          {webinar.imageCloudinaryId ? (
            <>
              {webinar.imageOverlayEnabled ? (
                <div className="absolute inset-0 z-10 bg-black/35" />
              ) : null}
              <Image
                alt={webinar.title || 'webinar-image'}
                className="absolute left-0 top-0 h-auto w-full"
                height={200}
                src={getCldOgImageUrl({
                  src: webinar.imageCloudinaryId,
                })}
                style={{ objectFit: 'cover' }}
                width={300}
              />
            </>
          ) : null}
        </div>
      </div>
      <div
        className={clsx(
          'flex h-full w-full flex-col justify-between p-4 md:p-6'
        )}
      >
        <div>
          <Typography className="mb-2 text-hubs-primary" variant="button">
            {webinar.title}
          </Typography>
          <Typography
            className="mb-4 text-hubs-secondary md:mb-2"
            variant="fine-print"
          >
            {dayjs(webinar.startTime).format('DD MMM YYYY')}
          </Typography>
          {webinar.summary ? (
            <div className="mb-2 line-clamp-2 max-h-12 overflow-hidden font-body text-hubs-secondary">
              <RenderBlockNote
                plaintext
                content={(webinar.summary as { content: Block[] }).content}
              />
            </div>
          ) : null}
        </div>
        <LinkButton className="mt-4 flex items-center gap-2 font-body text-sm !text-company-primary !no-underline">
          View webinar
          <ArrowRightIcon className="size-3.5 transition-transform group-hover:translate-x-1/4" />
        </LinkButton>
      </div>
    </Link>
  );
};
