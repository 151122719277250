import { SVGProps } from 'react';

export const PinFill: React.FC<SVGProps<SVGElement>> = ({
  className,
  height,
  width,
}) => {
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.742 1.08301C14.8406 1.08283 14.9382 1.10208 15.0293 1.13966C15.1204 1.17724 15.2032 1.23241 15.273 1.30201L22.698 8.72701C22.8386 8.86765 22.9176 9.05839 22.9176 9.25726C22.9176 9.45613 22.8386 9.64686 22.698 9.78751C21.978 10.5075 21.09 10.6695 20.4435 10.6695C20.178 10.6695 19.941 10.6425 19.7535 10.611L15.0525 15.312C15.1763 15.8106 15.2566 16.319 15.2925 16.8315C15.3615 17.8845 15.2445 19.362 14.2125 20.394C14.0719 20.5346 13.8811 20.6136 13.6823 20.6136C13.4834 20.6136 13.2927 20.5346 13.152 20.394L8.90851 16.152L4.13551 20.925C3.84301 21.2175 2.30701 22.278 2.01451 21.9855C1.72201 21.693 2.78251 20.1555 3.07501 19.8645L7.84801 15.0915L3.60601 10.848C3.4654 10.7074 3.38642 10.5166 3.38642 10.3178C3.38642 10.1189 3.4654 9.92815 3.60601 9.78751C4.63801 8.75551 6.11551 8.63701 7.16851 8.70751C7.68099 8.74342 8.1894 8.82372 8.68801 8.94751L13.389 4.24801C13.3498 4.01958 13.3297 3.78828 13.329 3.55651C13.329 2.91151 13.491 2.02351 14.2125 1.30201C14.3531 1.16181 14.5435 1.08305 14.742 1.08301Z"
        fill="currentcolor"
      />
    </svg>
  );
};

export default PinFill;
