import * as cookie from 'cookie';

const COOKIE_CONSENT_KEY = 'cookieConsent';
const COOKIE_CONSENT_AGE = 60 * 60 * 24 * 365; // 1 year

export interface CookieConsent {
  analytics: boolean;
  marketing: boolean;
}

export function setCookieConsent(consent: CookieConsent): void {
  const cookieValue = JSON.stringify(consent);
  const options = {
    maxAge: COOKIE_CONSENT_AGE,
    path: '/',
  };
  if (typeof document === 'undefined') {
    return;
  }

  document.cookie = cookie.serialize(COOKIE_CONSENT_KEY, cookieValue, options);
}

export function getCookieConsent(): CookieConsent | undefined {
  if (typeof document === 'undefined') {
    return;
  }

  const cookieHeader = document.cookie;
  const cookies = cookie.parse(cookieHeader);
  const cookieValue = cookies[COOKIE_CONSENT_KEY];

  if (cookieValue) {
    try {
      return JSON.parse(cookieValue);
    } catch (error) {
      return {
        analytics: false,
        marketing: false,
      };
    }
  }

  return undefined;
}
