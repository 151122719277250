import analytics from '@analytics';
import {
  ArrowsUpDownIcon,
  ClockIcon,
  HandThumbUpIcon,
} from '@heroicons/react-v2/24/outline';
import { Typography, Button } from '../../../index';

export const QuickSort: React.FC<{
  orders: {
    key: string;
    value: string;
  }[];
  setOrder: (order: string) => void;
}> = ({ orders, setOrder }) => {
  return (
    <div className="flex w-full items-center justify-end gap-4 md:w-auto">
      <div className="hidden items-center gap-2 text-hubs-secondary md:flex">
        <ArrowsUpDownIcon className="h-5 w-5 text-inherit" />
        <Typography className="text-inherit">Sort by</Typography>
      </div>
      <Button
        className={
          orders[0].key === 'posted_at'
            ? 'pointer-events-none'
            : 'pointer-events-auto'
        }
        color={orders[0].key === 'posted_at' ? 'company' : undefined}
        size="md"
        startIcon={<ClockIcon className="h-5 w-5" />}
        variant={orders[0].key === 'posted_at' ? 'primary' : 'secondary'}
        onClick={() => {
          analytics.track('hermes_sort_or_filter', {
            feature: 'announcements',
            hubs_version: '2',
            sort: 'latest',
          });
          return orders[0].key === 'posted_at' ? false : setOrder('posted_at');
        }}
      >
        Latest
      </Button>
      <Button
        className={
          orders[0].key === 'engagement'
            ? 'pointer-events-none'
            : 'pointer-events-auto'
        }
        color={orders[0].key === 'engagement' ? 'company' : undefined}
        size="md"
        startIcon={<HandThumbUpIcon className="h-5 w-5" />}
        variant={orders[0].key === 'engagement' ? 'primary' : 'secondary'}
        onClick={() => {
          analytics.track('hermes_sort_or_filter', {
            feature: 'announcements',
            hubs_version: '2',
            sort: 'most popular',
          });
          return orders[0].key === 'engagement'
            ? false
            : setOrder('engagement');
        }}
      >
        <span className="hidden md:inline">{'Most '}</span>popular
      </Button>
    </div>
  );
};
