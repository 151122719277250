export const ReportIcon: React.ComponentType<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      fill="currentColor"
      height="20"
      viewBox="0 0 16 20"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M3.20005 0.400024C2.56353 0.400024 1.95308 0.652881 1.50299 1.10297C1.05291 1.55306 0.800049 2.1635 0.800049 2.80002V17.2C0.800049 17.8365 1.05291 18.447 1.50299 18.8971C1.95308 19.3472 2.56353 19.6 3.20005 19.6H12.8C13.4366 19.6 14.047 19.3472 14.4971 18.8971C14.9472 18.447 15.2 17.8365 15.2 17.2V6.89682C15.1999 6.26036 14.947 5.65001 14.4968 5.20002L10.4 1.10322C9.95007 0.653106 9.33972 0.40016 8.70325 0.400024H3.20005ZM5.60005 12.4C5.60005 12.0818 5.47362 11.7765 5.24858 11.5515C5.02353 11.3265 4.71831 11.2 4.40005 11.2C4.08179 11.2 3.77656 11.3265 3.55152 11.5515C3.32648 11.7765 3.20005 12.0818 3.20005 12.4V16C3.20005 16.3183 3.32648 16.6235 3.55152 16.8486C3.77656 17.0736 4.08179 17.2 4.40005 17.2C4.71831 17.2 5.02353 17.0736 5.24858 16.8486C5.47362 16.6235 5.60005 16.3183 5.60005 16V12.4ZM8.00005 8.80002C8.31831 8.80002 8.62353 8.92645 8.84858 9.1515C9.07362 9.37654 9.20005 9.68176 9.20005 10V16C9.20005 16.3183 9.07362 16.6235 8.84858 16.8486C8.62353 17.0736 8.31831 17.2 8.00005 17.2C7.68179 17.2 7.37656 17.0736 7.15152 16.8486C6.92648 16.6235 6.80005 16.3183 6.80005 16V10C6.80005 9.68176 6.92648 9.37654 7.15152 9.1515C7.37656 8.92645 7.68179 8.80002 8.00005 8.80002ZM12.8 7.60002C12.8 7.28176 12.6736 6.97654 12.4486 6.7515C12.2235 6.52645 11.9183 6.40002 11.6 6.40002C11.2818 6.40002 10.9766 6.52645 10.7515 6.7515C10.5265 6.97654 10.4 7.28176 10.4 7.60002V16C10.4 16.3183 10.5265 16.6235 10.7515 16.8486C10.9766 17.0736 11.2818 17.2 11.6 17.2C11.9183 17.2 12.2235 17.0736 12.4486 16.8486C12.6736 16.6235 12.8 16.3183 12.8 16V7.60002Z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ReportIcon;
