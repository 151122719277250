import { AnalyticsBrowser } from '@segment/analytics-next';

const segment = new AnalyticsBrowser();

/**
 * A wrapper function for the segment.track method.
 *
 * @param event - The name of the event you're tracking.
 * @param properties - A dictionary of properties for the event. Optional.
 * @param options - Options for the track call. Optional.
 * @param callback - A callback function to be executed after the track call. Optional.
 */
function track(
  event: string,
  properties?: object,
  options?: object,
  callback?: () => void
): void {
  segment.track(event, properties, options, callback);
}

/**
 * A wrapper function for the segment.page method.
 *
 * @param category - The category of the page. Optional.
 * @param name - The name of the page. Optional.
 * @param properties - A dictionary of properties of the page. Optional.
 * @param options - A dictionary of options for the call. Optional.
 * @param callback - A callback function to be executed after the page call. Optional.
 */
function page(
  category?: string,
  name?: string,
  properties?: object,
  options?: object,
  callback?: () => void
): void {
  // If only one string argument is provided, it's assumed to be the name.
  if (category && !name && !properties && !options && !callback) {
    segment.page(category);
  } else {
    segment.page(category, name, properties, options, callback);
  }
}

function setAnonymousId(anonymousId: string): void {
  segment.setAnonymousId(anonymousId);
}

/**
 * A wrapper function for the segment.identify method.
 *
 * @param userId - The database ID for the user. Optional.
 * @param traits - A dictionary of traits you know about the user. Optional.
 * @param options - Options for the identify call. Optional.
 * @param callback - A callback function to be executed after the identify call. Optional.
 */
function identify(
  userId?: string,
  traits?: object,
  options?: object,
  callback?: () => void
): void {
  segment.identify(userId, traits, options, callback);
}

// Resets the identity. Used for logging out.
function reset(): void {
  segment.reset();
}

// Loads segment and destinations (e.g. Heap)
function loadIfConsented(): void {
  segment.load(
    { writeKey: process.env.NEXT_PUBLIC_SEGMENT_API_KEY || '' },
    {
      disableClientPersistence: true,
    }
  );
}

const analytics = {
  identify,
  loadIfConsented,
  page,
  reset,
  setAnonymousId,
  track,
};

export default analytics;
