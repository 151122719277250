import cookieParser from 'cookie';

const HERMES_LOGROCKET_SESSION_URL_COOKIE = '_hermes_logrocket_session_url';

const HERMES_LOGROCKET_SESSION_URL_COOKIE_OPTIONS: cookieParser.CookieSerializeOptions =
  {
    path: '/',
    secure: process.env.NODE_ENV === 'production',
  };

export function setCookie(sessionURL: string) {
  document.cookie = cookieParser.serialize(
    HERMES_LOGROCKET_SESSION_URL_COOKIE,
    sessionURL,
    HERMES_LOGROCKET_SESSION_URL_COOKIE_OPTIONS
  );
}
