import { ApolloError } from '@apollo/client';

export function formatError(error: unknown) {
  if (error instanceof ApolloError) {
    return error.graphQLErrors.map((e) => e.message).join(' ');
  }

  if (error instanceof Error) {
    return error.message;
  }

  return 'Oops! Something went wrong.';
}
