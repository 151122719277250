import React from 'react';
import clsx from 'clsx';
import Typography from './typography';

export interface PillButtonProps {
  active?: boolean;
  count: number;
  disabled?: boolean;
  icon?: React.ReactNode;
  label: string;
  onToggle: () => void;
}

export const PillButton: React.ComponentType<PillButtonProps> = ({
  active = false,
  count,
  disabled = false,
  icon,
  label,
  onToggle,
}) => {
  return (
    <button
      className={clsx(
        'group flex items-center space-x-2 rounded-lg bg-none p-2 text-hubs-fixed-dark disabled:text-text-disabled',
        active && 'bg-chart-green-light-2 active:bg-chart-green-light',
        !active &&
          'hover:bg-secondary-grey-light-2 active:bg-secondary-grey-light'
      )}
      disabled={disabled}
      onClick={disabled ? undefined : onToggle}
    >
      {icon}

      <Typography className="whitespace-nowrap" variant="subtitle-1">
        {label}
      </Typography>

      <Typography
        className={clsx(
          'rounded-[100px] bg-secondary-grey-light px-[6px] py-[2px]',
          active && 'bg-chart-green-dark text-white',
          !active && 'group-hover:bg-secondary-grey-light'
        )}
        variant="fine-print-bold"
      >
        {count}
      </Typography>
    </button>
  );
};

export default PillButton;
