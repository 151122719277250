export const AnnualReportIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      fill="none"
      height="64"
      viewBox="0 0 64 64"
      width="64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1539_27300)">
        <path
          d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
          fill="currentColor"
          style={{ color: 'var(--company-accent)' }}
        />
        <path
          d="M63.1717 24.737L47.9403 9.50563L42.3067 15.1459L34.3188 7.158L27.6886 13.7445L41.9937 28.0498L16.0537 53.9824L25.3852 63.3139C27.5197 63.7625 29.732 64 32 64C49.6731 64 64 49.6731 64 32C64 29.5018 63.7132 27.0705 63.1717 24.737Z"
          fill="currentColor"
          style={{ color: 'var(--company-accent)' }}
        />
        <path
          d="M16.0537 9.50562V53.9824H32L33.2252 31.744L32 10.5057L24.2581 9.50562H16.0537Z"
          fill="currentColor"
          style={{ color: 'var(--company-accent)' }}
        />
        <path
          d="M47.9404 9.50562H39.7419L32 10.5057V53.9824H47.9404V9.50562Z"
          fill="currentColor"
          style={{ color: 'var(--company-accent)' }}
        />
        <path
          d="M18.7764 12.2285V51.4361H31.9999L34.0195 31.8323L31.9999 12.2285H18.7764Z"
          fill="white"
        />
        <path d="M32 12.2285H45.2235V51.4361H32V12.2285Z" fill="#E3E3E3" />
        <path
          d="M31.9998 6.20588C30.1774 6.20588 28.7001 7.68313 28.7001 9.50563H24.2578V14.5983H31.9998L32.6433 10.4021L31.9998 6.20588Z"
          fill="#E3E3E3"
        />
        <path
          d="M35.2998 9.50563C35.2998 7.68325 33.8224 6.20588 32 6.20588V14.5981H39.7419V9.50563H35.2998Z"
          fill="#A7A7A7"
        />
        <path
          d="M22 22.7046V24.5796H32L32.567 23.6421L32 22.7046H22Z"
          fill="#E3E3E3"
        />
        <path d="M32 22.7046H42V24.5796H32V22.7046Z" fill="#A7A7A7" />
        <path
          d="M22 17.0796V20.8296H32L32.567 18.9546L32 17.0796H22Z"
          fill="#E3E3E3"
        />
        <path d="M32 17.0796H42V20.8296H32V17.0796Z" fill="#A7A7A7" />
        <path
          d="M22 26.4649V28.3399H32L32.567 27.4024L32 26.4649H22Z"
          fill="#E3E3E3"
        />
        <path d="M32 26.4649H42V28.3399H32V26.4649Z" fill="#A7A7A7" />
        <path
          d="M32.0002 30.2046C29.601 30.2046 27.2017 31.1199 25.3711 32.9505L32.0002 39.5796L33.1993 34.6521L32.0002 30.2046Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary-text)' }}
        />
        <path
          d="M32 48.9546L32.8986 44.3709L32 39.5796L27.3125 38.7914L22.625 39.5796C22.625 41.9789 23.5402 44.3781 25.3709 46.2088C27.2015 48.0394 29.6007 48.9546 32 48.9546Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary-text)' }}
        />
        <path
          d="M38.6291 46.2088L35.9349 42.4765L32 39.5796V48.9546C34.3993 48.9546 36.7985 48.0394 38.6291 46.2088Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary-text)' }}
        />
        <path
          d="M25.3709 32.9505C23.5402 34.7811 22.625 37.1804 22.625 39.5796H32L25.3709 32.9505Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary-text)' }}
        />
        <path
          d="M38.6291 32.9505C36.7985 31.1199 34.3993 30.2046 32 30.2046V39.5796L38.6291 46.2088C42.2902 42.5476 42.2902 36.6116 38.6291 32.9505Z"
          fill="currentColor"
          style={{ color: 'var(--company-primary)' }}
        />
      </g>
      <defs>
        <clipPath id="clip0_1539_27300">
          <rect fill="white" height="64" width="64" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AnnualReportIcon;
