import clsx from 'clsx';
import { getCldOgImageUrl } from 'next-cloudinary';
import { type GetWebinarDataQuery } from '../../../apollo/generated';

interface WebinarBackgroundContainerProps {
  children: React.ReactNode;
  webinar: NonNullable<
    NonNullable<GetWebinarDataQuery['webinarData']>['webinar']
  >;
}

const getBgImageStyle = (webinar: WebinarBackgroundContainerProps['webinar']) =>
  webinar.imageCloudinaryId
    ? {
        backgroundImage: `url(${getCldOgImageUrl({
          src: webinar.imageCloudinaryId,
        })})`,
      }
    : {};

export const WebinarBackgroundContainer: React.FC<
  WebinarBackgroundContainerProps
> = ({ children, webinar }) => {
  return (
    <div
      className={clsx(
        'flex h-[650px] flex-col items-center justify-center bg-company-primary bg-cover text-company-primary-text',
        'relative before:absolute before:inset-0 before:block before:h-full before:w-full before:bg-black/35 before:content-[""]',
        webinar.imageOverlayEnabled && !!webinar.imageCloudinaryId
          ? 'before:visible'
          : 'before:hidden'
      )}
      style={getBgImageStyle(webinar)}
    >
      {children}
    </div>
  );
};
