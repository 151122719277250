import { SVGProps } from 'react';

export const PlayButtonIcon: React.FC<SVGProps<SVGElement>> = () => {
  return (
    <svg
      height="42"
      viewBox="0 0 42 42"
      width="42"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
        fill="white"
        opacity="0.9"
      />
      <path
        d="M27.7519 20.1678L18.5547 14.0363C17.8902 13.5933 17 14.0697 17 14.8684V27.1314C17 27.9301 17.8902 28.4064 18.5547 27.9634L27.7519 21.8319C28.3456 21.4361 28.3456 20.5636 27.7519 20.1678Z"
        fill="inherit"
      />
    </svg>
  );
};

export default PlayButtonIcon;
