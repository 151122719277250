// DS V2
import { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Listbox } from '@headlessui/react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Typography, Button, TextArea } from '@leaf/ui';
import { useContactUsMutation } from '@/apollo/generated';

import { useAlert } from '@/contexts/alert-context';

interface FormData {
  message: string;
}

interface Props {
  defaultSelection?: number;
  onClose(): void;
  open: boolean;
}

const ENQUIRY_TYPES = [
  'General enquiry',
  'Product issues',
  'Subscriptions',
  'Share registry account',
  'Close account',
];

const ContactFreshModal: React.ComponentType<Props> = ({
  defaultSelection = 0,
  onClose,
  open,
}) => {
  const { formatAndShowError, showAlert } = useAlert();
  const [contactUs] = useContactUsMutation();

  const [selectedEnquiry, setSelectedEnquiry] = useState(
    ENQUIRY_TYPES[defaultSelection]
  );

  const { formState, handleSubmit, register, reset } = useForm<FormData>({
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        message: yup.string().trim().required('Required.'),
      })
    ),
  });

  useEffect(() => {
    reset({
      message: '',
    });
    setSelectedEnquiry(ENQUIRY_TYPES[defaultSelection]);
  }, [defaultSelection, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const result = await contactUs({
        variables: {
          message: data.message,
          type: selectedEnquiry,
        },
      });

      if (!result.data?.contactUs) {
        throw new Error('Unable to send enquiry.');
      }

      onClose();

      reset();

      setSelectedEnquiry(ENQUIRY_TYPES[defaultSelection]);

      showAlert({
        description:
          "We've received your message and a member of our team will be in touch with you shortly.",
        variant: 'success',
      });
    } catch (error) {
      formatAndShowError(error);
    }
  });

  const renderContent = () => {
    return (
      <form className="space-y-8" onSubmit={onSubmit}>
        <div className="space-y-2">
          <Typography className="text-text-main" variant="heading-4">
            Contact InvestorHub
          </Typography>
          <Typography className="text-hubs-secondary" variant="body-regular">
            Fill in the details below and a InvestorHub representative will be
            in touch with you soon.
          </Typography>
        </div>
        <div className="space-y-4">
          <div>
            <label className="mb-1 block" htmlFor="type">
              <Typography component="h4" variant="subtitle-2">
                Select enquiry type
              </Typography>
            </label>
            <Listbox
              as="div"
              className="relative z-50"
              value={selectedEnquiry}
              onChange={(value: string) => {
                setSelectedEnquiry(value);
              }}
            >
              <Listbox.Button className="inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border bg-hubs-background px-3.5 py-2 font-sans text-sm font-medium leading-5 tracking-normal text-gray-700 shadow-sm outline-none transition duration-200 ease-linear hover:border-gray-300 hover:bg-hubs-background-accent hover:text-gray-900 hover:shadow-sm focus:border-gray-300 focus:bg-white active:border-gray-300 active:bg-gray-100 active:text-gray-900 active:shadow-sm disabled:border-gray-200 disabled:bg-white disabled:text-gray-300 disabled:shadow-sm disabled:hover:bg-white">
                {selectedEnquiry}
                <ChevronDownIcon className="h-5 w-5" />
              </Listbox.Button>
              <Listbox.Options className="absolute inset-x-0 mt-2 max-w-xs overflow-hidden rounded-md bg-hubs-background shadow-lg">
                {ENQUIRY_TYPES.map((item) => (
                  <Listbox.Option
                    key={item}
                    className={clsx(
                      'flex cursor-pointer items-center justify-between gap-1 px-4 py-3',
                      item === selectedEnquiry
                        ? 'bg-chart-green-med'
                        : 'hover:bg-hubs-background-accent'
                    )}
                    value={item}
                  >
                    <Typography
                      className="text-gray-900"
                      component="span"
                      variant="body-small"
                    >
                      {item}
                    </Typography>
                    {item === selectedEnquiry && (
                      <CheckIcon className="h-5 w-5 text-gray-900" />
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Listbox>
          </div>
          <TextArea
            {...register('message')}
            error={!!formState.errors.message?.message}
            helperText={formState.errors.message?.message}
            id="message"
            label="Please provide further details about your enquiry"
            rows={5}
          />
        </div>
        <div className="flex flex-col gap-4 sm:flex-row-reverse">
          <Button
            className="flex-1"
            disabled={formState.isSubmitting}
            type="submit"
            variant="primary"
          >
            Send enquiry
          </Button>
          <Button className="flex-1" variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </form>
    );
  };

  return (
    <Transition.Root as={Fragment} show={open}>
      <Dialog className="fixed inset-0 z-40" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-primary-off-black/30" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="translate-y-full"
          enterTo="translate-y-0"
          leave="ease-in duration-200"
          leaveFrom="translate-y-0"
          leaveTo="translate-y-full"
        >
          <div className="flex h-full flex-col items-center sm:p-6">
            <div className="flex-1" />
            <div className="relative h-auto w-full max-w-lg rounded-lg bg-hubs-background shadow-lg sm:max-w-lg">
              <div className="absolute right-0 top-0 z-10 block pr-4 pt-4">
                <button
                  className="rounded-md bg-hubs-background text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  type="button"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <XIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>

              <div className="sm:rounded-lg">
                <div className="h-2 bg-company-primary" />
                <div className="space-y-12 p-6">{renderContent()}</div>
              </div>
            </div>
            <div className="hidden flex-1 sm:block" />
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default ContactFreshModal;
