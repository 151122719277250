export const VideoIcon: React.ComponentType<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      fill="currentColor"
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.25 8V14H5.75V8H13.25ZM14.75 9.875V7.25C14.75 7.05109 14.671 6.86032 14.5303 6.71967C14.3897 6.57902 14.1989 6.5 14 6.5H5C4.80109 6.5 4.61032 6.57902 4.46967 6.71967C4.32902 6.86032 4.25 7.05109 4.25 7.25V14.75C4.25 14.9489 4.32902 15.1397 4.46967 15.2803C4.61032 15.421 4.80109 15.5 5 15.5H14C14.1989 15.5 14.3897 15.421 14.5303 15.2803C14.671 15.1397 14.75 14.9489 14.75 14.75V12.125L16.4675 13.8425C16.5724 13.9483 16.7064 14.0204 16.8525 14.0497C16.9986 14.079 17.15 14.0642 17.2876 14.007C17.4252 13.9499 17.5426 13.8531 17.625 13.729C17.7073 13.6048 17.7509 13.459 17.75 13.31V8.6825C17.7509 8.53353 17.7073 8.38768 17.625 8.26354C17.5426 8.1394 17.4252 8.0426 17.2876 7.98547C17.15 7.92835 16.9986 7.91349 16.8525 7.94279C16.7064 7.97209 16.5724 8.04423 16.4675 8.15L14.75 9.875Z" />
    </svg>
  );
};

export default VideoIcon;
