export const TextIcon: React.ComponentType<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      className="h-6 w-6"
      fill="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 -6 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 10H6V8.33333H0V10ZM0 4.16667V5.83333H12V4.16667H0ZM0 0V1.66667H18V0H0Z" />
    </svg>
  );
};

export default TextIcon;
