import React, { useEffect, useRef } from 'react';
import { CodeBracketSquareIcon } from '@heroicons/react-v2/24/outline';
import clsx from 'clsx';
import { BlockHeading } from '../../typography';
import { BLOCK_WIDTH_CLASS } from '../constants';
import { BlockDesign } from '../types';

export interface EmbedBlockProps {
  content: {
    design?: BlockDesign;
    embed?: string;
    fullWidth?: boolean;
    heading?: string;
  };
}

export const EmbedBlock: React.ComponentType<EmbedBlockProps> = ({
  content,
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof window !== 'undefined' && divRef.current) {
      // On client side rendering reload the script on embed block
      const existingScripts = divRef.current.querySelectorAll('script');

      existingScripts.forEach((existingScript) => {
        const newScript = document.createElement('script');
        newScript.textContent = existingScript.textContent;

        existingScript.getAttributeNames().forEach((attribute) => {
          newScript.setAttribute(
            attribute,
            existingScript.getAttribute(attribute) || ''
          );
        });

        // Replace the existing script with the new one using the parent node
        existingScript.parentNode?.replaceChild(newScript, existingScript);
      });
    }
  }, []);

  return (
    <div
      className={clsx(
        content.fullWidth ? 'w-full' : BLOCK_WIDTH_CLASS,
        content.design?.removeTopSpacing && '-mt-16 md:-mt-20',
        content.design?.removeBottomSpacing && '-mb-16 md:-mb-20'
      )}
    >
      {content.heading ? (
        <div className={content.fullWidth ? BLOCK_WIDTH_CLASS : undefined}>
          <BlockHeading>{content.heading}</BlockHeading>
        </div>
      ) : null}
      {content.embed ? (
        <div dangerouslySetInnerHTML={{ __html: content.embed }} ref={divRef} />
      ) : (
        <div className="flex h-[400px] justify-center">
          <div className="flex w-full items-center justify-center gap-2 rounded-lg bg-gray-100 p-4 text-gray-500">
            <CodeBracketSquareIcon className="h-6 w-6" />
            <span className="text-sm">
              Embed section awaiting content. Please add your embed code to
              display content here.
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
