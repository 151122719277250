import { ApolloError } from '@apollo/client';
import cookieParser from 'cookie';
import { BLOCK_CLICKED_EVENT } from './constants';

// This is for when a user is in the hub builder and clicks on a block - it automatically loads the editor in the sidebar for that block
// Pairs with useBlockClickNavigation
export const emitBlockClickEvent = (blockId?: string) => {
  if (blockId) {
    // event must match the one in useBlockClickNavigation()
    const event = new CustomEvent(BLOCK_CLICKED_EVENT, { detail: blockId });
    window.dispatchEvent(event);
  }
};

const HERMES_RETURN_TO_COOKIE = '_hermes_return_to';

const HERMES_RETURN_TO_COOKIE_OPTIONS: cookieParser.CookieSerializeOptions = {
  path: '/',
  secure: process.env.NODE_ENV === 'production',
};

export const setReturnToCookie = (pathname: string): void => {
  document.cookie = cookieParser.serialize(
    HERMES_RETURN_TO_COOKIE,
    pathname,
    HERMES_RETURN_TO_COOKIE_OPTIONS
  );
};
export const getReturnToCookiePath = (): string | undefined => {
  const cookie = cookieParser.parse(document.cookie);

  return cookie[HERMES_RETURN_TO_COOKIE];
};
export const clearReturnToCookie = () => {
  document.cookie = cookieParser.serialize(HERMES_RETURN_TO_COOKIE, '', {
    ...HERMES_RETURN_TO_COOKIE_OPTIONS,
    maxAge: 0,
  });
};

export function formatError(error: unknown) {
  if (error instanceof ApolloError) {
    return error.graphQLErrors.map((e) => e.message).join(' ');
  }

  if (error instanceof Error) {
    return error.message;
  }

  return 'Oops! Something went wrong.';
}
