import { RefObject } from 'react';
import dayjs from 'dayjs';
import { MouseEventParams, Point } from 'lightweight-charts';
import numeral from 'numeral';
import { renderToString } from 'react-dom/server';
import { Typography, MediaAnnouncement } from '../../../index';
import {
  AnnouncementsSymbol,
  SharePriceSymbol,
  SymbolVariant,
  VolumeSymbol,
} from '../market-data';

const TOOLTIP_X_OFFSET = 135;

const calculateTipXOffset = (
  point: Point,
  chartContainerRef: RefObject<HTMLDivElement>,
  tooltipRef: RefObject<HTMLSpanElement>
): number => {
  const chartContainerOffsetWidth = chartContainerRef.current?.offsetWidth;
  const tooltipOffsetWidth = tooltipRef.current?.offsetWidth;

  if (
    chartContainerOffsetWidth &&
    tooltipOffsetWidth &&
    point.x + tooltipOffsetWidth > chartContainerOffsetWidth
  ) {
    return TOOLTIP_X_OFFSET - tooltipOffsetWidth;
  } else {
    return TOOLTIP_X_OFFSET;
  }
};

export const crosshairMoveHandler = (
  params: MouseEventParams,
  chartContainerRef: RefObject<HTMLDivElement>,
  tooltipRef: RefObject<HTMLSpanElement>,
  dateMappedAnnouncements: Record<string, MediaAnnouncement[]>,
  translate: (
    key: string,
    params?:
      | {
          [key: string]: string | number;
        }
      | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any
) => {
  const { seriesData: seriesDataMap } = params;
  const mapData = Array.from(seriesDataMap.values());
  const volumeData = mapData[0] as unknown as { time: number; value: number };
  const sharePriceData = mapData[1] as unknown as {
    time: string;
    value: string;
  };

  if (volumeData?.time) {
    const crosshairDate = new Date(volumeData.time * 1000);
    const date = dayjs(crosshairDate).format('D MMM YYYY');
    const dateKey = dayjs(crosshairDate).format('YYYY-MM-DD');
    const announcements = dateMappedAnnouncements[dateKey] ?? [];
    const volume = volumeData?.value;
    const sharePrice = numeral(sharePriceData?.value).format('0,0[.]00[0]');
    const { point, time } = params;

    if (chartContainerRef.current && tooltipRef.current) {
      if (
        point === undefined ||
        !time ||
        (point && point.x < 0) ||
        (point && point.x > chartContainerRef.current.offsetWidth) ||
        (point && point.y < 0) ||
        (point && point.y > chartContainerRef.current.offsetHeight)
      ) {
        tooltipRef.current.style.display = 'none';
        return;
      }
    }

    if (tooltipRef.current && point) {
      tooltipRef.current.innerHTML = renderToString(
        <CrosshairTooltip
          announcements={announcements}
          date={date}
          sharePrice={sharePrice}
          translate={translate}
          volume={volume}
        />
      );

      const tipXOffset = calculateTipXOffset(
        point,
        chartContainerRef,
        tooltipRef
      );
      const tipYOffset = announcements.reduce((acc, _ann, idx) => {
        return idx === 0 ? acc - 44 : acc - 22;
      }, -42);

      tooltipRef.current.style.position = 'absolute';
      tooltipRef.current.style.zIndex = '10';
      tooltipRef.current.style.left = `${point.x + tipXOffset}px`;
      tooltipRef.current.style.top = `${point.y + tipYOffset}px`;
    }
  }
};

interface CrosshairTooltipProps {
  announcements: MediaAnnouncement[];
  date: string;
  sharePrice: string;
  translate: (
    key: string,
    params?:
      | {
          [key: string]: string | number;
        }
      | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any;
  volume: number;
}

const CrosshairTooltip = ({
  announcements,
  date,
  sharePrice,
  translate,
  volume,
}: CrosshairTooltipProps): JSX.Element => {
  return (
    <div
      className="market-data-tt-box flex min-w-[197px] max-w-xs flex-col gap-1.5 rounded-lg bg-primary-grey-dark px-4 py-3 text-white"
      id="market-data-crosshair-tt"
    >
      <Typography className="font-semibold" variant="system-regular">
        {date}
      </Typography>
      <div className="flex flex-row gap-2">
        <SharePriceSymbol className="my-auto" variant={SymbolVariant.Small} />
        <Typography className="my-auto" variant="system-small">
          Share price: {sharePrice}
        </Typography>
      </div>
      <div className="flex flex-row gap-2">
        <VolumeSymbol className="my-auto" />
        <Typography className="my-auto" variant="system-small">
          Volume: {volume.toLocaleString('en-AU')}
        </Typography>
      </div>

      {announcements.length > 0 && (
        <div className="flex flex-col gap-1">
          <div className="flex flex-row space-x-2">
            <AnnouncementsSymbol withBorder className="my-auto" />
            <Typography className="my-auto" variant="system-small">
              {translate('announcements.uppercase')}:
            </Typography>
          </div>
          <ul className="ml-5 flex list-inside list-disc flex-col gap-1">
            {announcements.map((announcement, idx) => {
              return (
                <li
                  key={idx}
                  className="typography-system-small list-item truncate whitespace-nowrap"
                >
                  <a
                    className="underline"
                    href={`/announcements/${announcement.id}`}
                  >
                    {announcement.header}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
