import { InformationCircleIcon } from '@heroicons/react/solid';
import Typography from './typography';

export interface PromptProps {
  cta?: React.ReactNode;
  description: string;
}

export const Prompt: React.ComponentType<PromptProps> = ({
  cta,
  description,
}) => {
  return (
    <div className="flex items-start gap-3 rounded-md bg-blue-50 p-4 text-chart-blue-dark">
      <InformationCircleIcon className="h-5 w-5" />
      <div className="flex min-w-0 flex-1 flex-col items-start gap-3 sm:flex-row sm:justify-between">
        <Typography>{description}</Typography>
        {cta}
      </div>
    </div>
  );
};

export default Prompt;
