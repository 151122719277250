import type { ContentBlock, ContentState } from 'draft-js';

interface Props {
  children?: React.ReactNode;
  contentState: ContentState;
  entityKey: string;
}

export function findLinkEntities(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}

const Link: React.ComponentType<Props> = ({
  children,
  contentState,
  entityKey,
}) => {
  const { url } = contentState.getEntity(entityKey).getData();

  return (
    <a className="text-inherit underline" href={url}>
      {children}
    </a>
  );
};

export default Link;
