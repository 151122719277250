export const TargetIcon: React.ComponentType<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      fill="none"
      height="22"
      stroke="currentColor"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.70851 8.2915C9.27543 8.49834 8.89829 8.80607 8.60876 9.18884C8.31923 9.57162 8.12577 10.0183 8.04461 10.4913C7.96345 10.9643 7.99697 11.4499 8.14234 11.9073C8.28772 12.3647 8.54071 12.7806 8.88008 13.1199C9.21945 13.4593 9.63529 13.7123 10.0927 13.8577C10.5501 14.003 11.0357 14.0366 11.5087 13.9554C11.9817 13.8742 12.4284 13.6808 12.8112 13.3913C13.1939 13.1017 13.5017 12.7246 13.7085 12.2915"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M13.14 4.86C12.47 4.6275 11.75 4.5 11 4.5C7.41 4.5 4.5 7.41 4.5 11C4.5 14.59 7.41 17.5 11 17.5C14.59 17.5 17.5 14.59 17.5 11C17.5 10.25 17.373 9.53 17.14 8.86"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M21 1.00001L11 11M15.784 2.21651C14.3167 1.41583 12.6715 0.99747 11 1.00001C5.477 1.00001 1 5.47701 1 11C1 16.523 5.477 21 11 21C16.523 21 21 16.523 21 11C21 9.26701 20.559 7.63701 19.784 6.21601L15.784 2.21651Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default TargetIcon;
