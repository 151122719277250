import { forwardRef } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import Typography from './typography';

export interface CurrencyInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: boolean;
  helperText?: string;
  label?: string;
  optional?: boolean;
}

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  ({ className, error, helperText, id, label, optional, ...props }, ref) => {
    return (
      <div>
        {!!label && (
          <div className="mb-1 flex items-center justify-between space-x-2">
            <label htmlFor={id}>
              <Typography component="span" variant="button">
                {label}
              </Typography>
            </label>
            {optional && (
              <Typography className="text-text-grey">Optional</Typography>
            )}
          </div>
        )}
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-text-grey sm:text-sm">$</span>
          </div>
          <input
            {...props}
            ref={ref}
            className={clsx(
              'input pl-7',
              error && 'input-error pr-11',
              className
            )}
            id={id}
            type="number"
          />
          {error && (
            <ExclamationCircleIcon className="absolute right-3 top-1/2 h-5 w-5 -translate-y-1/2 text-status-red" />
          )}
        </div>
        {!!helperText && (
          <Typography
            className={clsx(
              'mt-2',
              error ? 'text-status-red' : 'text-text-grey'
            )}
          >
            {helperText}
          </Typography>
        )}
      </div>
    );
  }
);

export default CurrencyInput;
