export const FormatTextIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      height="24"
      viewBox="0 0 22 24"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.36799 11.6714L11 7.33086L12.6251 11.6714H9.36799ZM10.3143 5.5L6.54285 15.1H8.0857L8.8537 13.0429H13.1394L13.9143 15.1H15.4571L11.6857 5.5H10.3143Z"
        stroke="none"
      />
      <line
        fill="none"
        strokeWidth="1.5"
        x1="6.54285"
        x2="15.4528"
        y1="17.75"
        y2="17.75"
      />
    </svg>
  );
};

export default FormatTextIcon;
