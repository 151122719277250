export const CompanyVerifiedIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M6.26698 3.45498C6.91024 3.40362 7.52089 3.15061 8.01198 2.73198C8.56664 2.25957 9.27141 2.00012 9.99998 2.00012C10.7286 2.00012 11.4333 2.25957 11.988 2.73198C12.4791 3.15061 13.0897 3.40362 13.733 3.45498C14.4594 3.51304 15.1414 3.82794 15.6567 4.34325C16.172 4.85855 16.4869 5.54055 16.545 6.26698C16.596 6.90998 16.849 7.52098 17.268 8.01198C17.7404 8.56664 17.9998 9.27141 17.9998 9.99998C17.9998 10.7286 17.7404 11.4333 17.268 11.988C16.8493 12.4791 16.5963 13.0897 16.545 13.733C16.4869 14.4594 16.172 15.1414 15.6567 15.6567C15.1414 16.172 14.4594 16.4869 13.733 16.545C13.0897 16.5963 12.4791 16.8493 11.988 17.268C11.4333 17.7404 10.7286 17.9998 9.99998 17.9998C9.27141 17.9998 8.56664 17.7404 8.01198 17.268C7.52089 16.8493 6.91024 16.5963 6.26698 16.545C5.54055 16.4869 4.85855 16.172 4.34325 15.6567C3.82794 15.1414 3.51304 14.4594 3.45498 13.733C3.40362 13.0897 3.15061 12.4791 2.73198 11.988C2.25957 11.4333 2.00012 10.7286 2.00012 9.99998C2.00012 9.27141 2.25957 8.56664 2.73198 8.01198C3.15061 7.52089 3.40362 6.91024 3.45498 6.26698C3.51304 5.54055 3.82794 4.85855 4.34325 4.34325C4.85855 3.82794 5.54055 3.51304 6.26698 3.45498ZM13.707 8.70698C13.8891 8.51838 13.9899 8.26578 13.9877 8.00358C13.9854 7.74138 13.8802 7.49057 13.6948 7.30516C13.5094 7.11976 13.2586 7.01459 12.9964 7.01231C12.7342 7.01003 12.4816 7.11082 12.293 7.29298L8.99998 10.586L7.70698 9.29298C7.51838 9.11082 7.26578 9.01003 7.00358 9.01231C6.74138 9.01459 6.49057 9.11976 6.30516 9.30516C6.11976 9.49057 6.01459 9.74138 6.01231 10.0036C6.01003 10.2658 6.11082 10.5184 6.29298 10.707L8.29298 12.707C8.48051 12.8945 8.73482 12.9998 8.99998 12.9998C9.26515 12.9998 9.51945 12.8945 9.70698 12.707L13.707 8.70698Z"
        fill="#4FA4FF"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CompanyVerifiedIcon;
