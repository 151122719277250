import React, { useMemo } from 'react';
import { useCallback, useEffect, useState } from 'react';
import analytics from '@analytics';
import {
  ApolloClient,
  NetworkStatus,
  NormalizedCacheObject,
} from '@apollo/client';
import 'regenerator-runtime/runtime';
import { InboxIcon, SearchIcon } from '@heroicons/react/solid';
import * as Sentry from '@sentry/nextjs';
import dayjs from 'dayjs';
import { useAsyncDebounce } from 'react-table';
import {
  FilterInput,
  useMediaUpdatesQuery,
  AppliedFilterBadges,
  FilterButton,
  FilterType,
  LinkButton,
  Skeleton,
  SortButton,
  TextInput,
  Typography,
  dateGetter,
  getAppliedFiltersWithLabels,
  calculateStartCursor,
  StartCursor,
  UpdatesBlockFilterModal,
  UpdatesBlockListItem,
  BlockHeading,
  Button,
  UpdatesBlockGridItem,
  MediaUpdatesQuery,
} from '../../../index';

interface Props {
  client?: ApolloClient<NormalizedCacheObject>;
  companyName: string;
  heading?: string;
  hub: string;
  listingKey: string;
  logoUrl: string;
  marketKey: string;
  tags?: string[];
  variant?: 'grid' | 'list';
}

export type MediaUpdateInBlock = NonNullable<
  NonNullable<
    NonNullable<NonNullable<MediaUpdatesQuery['mediaUpdates']>['edges']>[0]
  >['node']
>;

const MEDIA_TYPES = ['image', 'video', 'pdf', 'url', 'none'];

const MEDIA_TYPE_LABELS = {
  endDate: dateGetter,
  image: 'Image',
  none: 'No media',
  pdf: 'PDF',
  startDate: dateGetter,
  url: 'Link',
  video: 'Video',
};
export const MEDIA_TYPE_KEY_LABELS = MEDIA_TYPES.map((key) => ({
  key,
  label: MEDIA_TYPE_LABELS[key as keyof typeof MEDIA_TYPE_LABELS],
}));

export const UpdatesBlock: React.FC<Props> = ({
  client,
  companyName,
  heading,
  hub,
  listingKey,
  logoUrl,
  marketKey,
  tags,
  variant = 'list',
}) => {
  const ROWS_PER_PAGE = variant === 'list' ? 5 : 6;
  const [startCursor, setStartCursor] = useState(StartCursor);
  const [totalCount, setTotalCount] = useState(0);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [value, setValue] = useState(searchPhrase);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<FilterType[]>([]);

  const [sortDirection, setSortDirection] = useState('desc');

  const onChange = useAsyncDebounce((value) => {
    setSearchPhrase(value);
    analytics.track('hermes_sort_or_filter', {
      feature: 'updates',
      filter: 'search',
      hubs_version: '1',
    });
  }, 350);

  const originalFilters = useMemo(
    () => (tags?.length ? [{ key: 'tags', value: tags.join(',') }] : []),
    [tags]
  );

  const [appliedFilters, setAppliedFilters] =
    useState<FilterType[]>(originalFilters);

  const getFilters = useCallback(() => {
    const filters = [
      ...appliedFilters
        .filter((filter) => !MEDIA_TYPES.includes(filter.key))
        .map((filter) => {
          if (filter.key === 'startDate') {
            return filter.value
              ? {
                  key: 'posted_at_greater_than',
                  value: dayjs(filter.value as Date).toISOString(),
                }
              : null;
          }
          if (filter.key === 'endDate') {
            return filter.value
              ? {
                  key: 'posted_at_less_than',
                  value: dayjs(filter.value as Date).toISOString(),
                }
              : null;
          }
          return filter;
        })
        .filter((f) => !!f && f.key !== 'tags'),
      { key: 'tags', value: (tags || []).join(',') },
    ];

    if (appliedFilters.some((af) => MEDIA_TYPES.includes(af.key))) {
      filters.push({
        key: 'types',
        value: appliedFilters
          .filter((filter) => MEDIA_TYPES.includes(filter.key))
          .map((af) => af.key)
          .join(','),
      });
    }

    if (searchPhrase) {
      filters.push({ key: 'search', value: searchPhrase });
    }

    return filters as FilterInput[];
  }, [appliedFilters, searchPhrase, tags]);

  const {
    client: returnedClient,
    data,
    fetchMore,
    loading,
    networkStatus,
  } = useMediaUpdatesQuery({
    client,
    variables: {
      after: startCursor,
      first: ROWS_PER_PAGE,
      hub: hub,
      options: {
        filters: getFilters(),
        orders: [
          { key: 'is_pinned', value: 'desc' },
          { key: 'posted_at', value: sortDirection },
        ],
      },
    },
  });

  useEffect(() => {
    const resetStore = async () => await returnedClient.resetStore();
    return () => {
      resetStore();
    };
  }, [returnedClient]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.ready)
      setTotalCount(data?.mediaUpdates?.total ?? 0);
  }, [data, networkStatus]);

  useEffect(() => {
    if (data && data.mediaUpdates) {
      setTotalCount(data.mediaUpdates.total);
    }
  }, [data]);

  useEffect(() => {
    calculateStartCursor(1, ROWS_PER_PAGE, setStartCursor);
  }, [ROWS_PER_PAGE]);

  const renderNoResults = useCallback(
    () => (
      <div className="flex flex-col items-center space-y-4">
        <InboxIcon className="h-5 w-5" />
        <Typography variant="heading-4">No results found</Typography>
        {!!searchPhrase || !!appliedFilters.length ? (
          <>
            <Typography className="text-hubs-secondary">
              Try adjusting your search or filter to find what you're looking
              for.
            </Typography>
            <LinkButton
              onClick={() => {
                setSelectedFilters([]);
                setAppliedFilters(originalFilters);
                onChange('');
                setValue('');
              }}
            >
              Clear filters
            </LinkButton>
          </>
        ) : (
          <Typography className="text-hubs-secondary">
            {`No updates found for ${listingKey}:${marketKey}, please check back another time`}
          </Typography>
        )}
      </div>
    ),
    [
      appliedFilters.length,
      searchPhrase,
      listingKey,
      marketKey,
      onChange,
      originalFilters,
    ]
  );

  const renderList = useCallback(() => {
    if (loading) {
      return (
        <div className="grid gap-8">
          {Array.from(new Array(ROWS_PER_PAGE).keys()).map((item) => (
            <Skeleton
              key={`updates-loading-${item}`}
              loading
              height={289}
              variant="rect"
            />
          ))}
        </div>
      );
    }

    if (data?.mediaUpdates?.edges?.length) {
      return (
        <div className="grid gap-8">
          {data?.mediaUpdates?.edges?.map((edge) =>
            edge?.node ? (
              <UpdatesBlockListItem
                key={`updates-block-item-${edge.node.id}`}
                companyName={companyName}
                listingKey={listingKey}
                logoUrl={logoUrl}
                marketKey={marketKey}
                mediaUpdate={edge.node}
              />
            ) : null
          )}
        </div>
      );
    }
    return renderNoResults();
  }, [
    listingKey,
    marketKey,
    data?.mediaUpdates,
    loading,
    companyName,
    logoUrl,
    ROWS_PER_PAGE,
    renderNoResults,
  ]);

  const rendergrid = useCallback(() => {
    if (loading) {
      return (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6">
          {[1, 2, 3].map((v) => (
            <div
              key={`updates-loading-grid-${v}`}
              className="h-32 w-full animate-pulse rounded-lg bg-secondary-grey-light md:h-[272px]"
            />
          ))}
        </div>
      );
    }

    if (data?.mediaUpdates?.edges?.length) {
      return (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6">
          {data?.mediaUpdates?.edges?.map((edge) =>
            edge?.node ? (
              <UpdatesBlockGridItem
                key={`updates-grid-item-${edge.node.id}`}
                companyName={companyName}
                listingKey={listingKey}
                logoUrl={logoUrl}
                marketKey={marketKey}
                mediaUpdate={edge.node}
              />
            ) : null
          )}
        </div>
      );
    }
    return renderNoResults();
  }, [
    listingKey,
    marketKey,
    data?.mediaUpdates,
    loading,
    companyName,
    logoUrl,
    renderNoResults,
  ]);

  const hasNextPage = !!data?.mediaUpdates?.pageInfo.hasNextPage;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

  const onLoadMore = useCallback(() => {
    fetchMore({
      variables: {
        after: data?.mediaUpdates?.pageInfo.endCursor,
        first: ROWS_PER_PAGE,
      },
    }).catch((e) => {
      Sentry.captureException(e);
    });
  }, [data, fetchMore, ROWS_PER_PAGE]);

  return (
    <div
      className="relative mx-auto w-screen max-w-screen-xl scroll-mt-40 px-4 sm:px-6"
      id="updates_block"
    >
      {heading && heading.length > 0 && (
        <BlockHeading className="mb-4">{heading}</BlockHeading>
      )}
      <div className="mb-8 flex w-full flex-col items-center justify-between gap-6 md:w-auto md:flex-row">
        <div className="flex w-full grow md:w-auto">
          <TextInput
            className="rounded-lg"
            leadingIcon={SearchIcon}
            placeholder="Search title"
            style={{ flexGrow: 1 }}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </div>
        <div className="flex w-full items-center gap-4 md:w-auto">
          <SortButton
            options={[
              {
                isActive: sortDirection === 'desc',
                label: 'Latest',
                onSort: () => setSortDirection('desc'),
                value: 'desc',
              },
              {
                isActive: sortDirection === 'asc',
                label: 'Oldest',
                onSort: () => setSortDirection('asc'),
                value: 'asc',
              },
            ]}
          />
          <FilterButton
            filtersLength={
              tags ? appliedFilters.length - 1 : appliedFilters.length
            }
            setModalOpen={setFiltersModalOpen}
          />
        </div>
      </div>
      <AppliedFilterBadges
        appliedFiltersWithLabels={getAppliedFiltersWithLabels(
          appliedFilters,
          MEDIA_TYPE_LABELS
        )}
        originalFilters={originalFilters}
        resultsCount={totalCount}
        setAppliedFilters={setAppliedFilters}
        setSelectedFilters={setSelectedFilters}
      />

      <div className="mt-6">
        {variant === 'list' ? renderList() : rendergrid()}

        {hasNextPage && (
          <div className="flex items-center justify-center pt-10">
            <Button
              className="w-full md:w-auto"
              disabled={isLoadingMore}
              variant="primary"
              onClick={onLoadMore}
            >
              Load more
            </Button>
          </div>
        )}
      </div>
      <UpdatesBlockFilterModal
        appliedFilters={appliedFilters}
        open={filtersModalOpen}
        originalFilters={originalFilters}
        selectedFilters={selectedFilters}
        setAppliedFilters={setAppliedFilters}
        setSelectedFilters={setSelectedFilters}
        onClose={() => setFiltersModalOpen(false)}
      />
    </div>
  );
};
