import clsx from 'clsx';

export interface SkeletonProps {
  children?: React.ReactElement;
  // To be used with `text` variant.
  fontSize?:
    | 'display-large'
    | 'large'
    | 'medium'
    | 'small'
    | 'heading-1'
    | 'heading-2'
    | 'heading-3'
    | 'heading-4'
    | 'heading-5'
    | 'subtitle-1'
    | 'subtitle-2'
    | 'body-large'
    | 'body-regular'
    | 'body-small'
    | 'paragraph'
    | 'fine-print-bold'
    | 'fine-print'
    | 'button'
    | 'badge';
  // To be used with `rect` variant.
  height?: number | string;
  loading?: boolean;
  // To be used with `circle` variant.
  size?: number;
  variant?: 'circle' | 'rect' | 'text';
  // To be used with `rect` variant.
  width?: number | string;
}

export const Skeleton: React.ComponentType<SkeletonProps> = ({
  children = null,
  fontSize = 'body-regular',
  height,
  loading,
  size,
  variant = 'text',
  width = '100%',
}) => {
  if (loading) {
    if (variant === 'circle') {
      return (
        <div
          className="animate-pulse rounded-full bg-secondary-grey-light"
          style={{ height: size, minHeight: size, minWidth: size, width: size }}
        />
      );
    }

    if (variant === 'rect') {
      return (
        <div
          className="animate-pulse rounded-lg bg-secondary-grey-light"
          style={{ height, minHeight: height, minWidth: width, width }}
        />
      );
    }

    if (variant === 'text') {
      return (
        <div
          className={clsx(
            'animate-pulse rounded bg-secondary-grey-light',
            fontSize === 'display-large' &&
              'h-[72px] scale-y-[.66] lg:h-[108px]',
            fontSize === 'large' && 'h-10 scale-y-[.8] lg:h-[60px]',
            fontSize === 'medium' && 'h-[30px] scale-y-[.8] lg:h-10',
            fontSize === 'small' && 'h-5 scale-y-[.8] lg:h-[30px]',
            fontSize === 'heading-1' && 'h-10 scale-y-[.8] lg:h-[50px]',
            fontSize === 'heading-2' && 'h-[35px] scale-y-[.8] lg:h-10',
            fontSize === 'heading-3' && 'h-[30px] scale-y-[.8] lg:h-[35px]',
            fontSize === 'heading-4' && 'h-[25px] scale-y-[.8] lg:h-[30px]',
            fontSize === 'heading-5' && 'h-[22.5px] scale-y-[.8] lg:h-[25px]',
            fontSize === 'subtitle-1' && 'h-[22.5px] scale-y-[.8]',
            fontSize === 'subtitle-2' && 'h-5 scale-y-[.8]',
            fontSize === 'body-large' && 'h-[22.5px] scale-y-[.8]',
            fontSize === 'body-regular' && 'h-5 scale-y-[.8]',
            fontSize === 'body-small' && 'h-[17.5px] scale-y-[.8]',
            fontSize === 'paragraph' && 'h-6 scale-y-[.66]',
            fontSize === 'fine-print-bold' && 'h-[18px] scale-y-[.66]',
            fontSize === 'fine-print' && 'h-[18px] scale-y-[.66]',
            fontSize === 'button' && 'h-5 scale-y-[.8]',
            fontSize === 'badge' && 'h-[17.5px] scale-y-[.8]'
          )}
          style={{ width }}
        />
      );
    }
  }

  return children;
};

export default Skeleton;
