import React, {
  createContext,
  useState,
  useContext,
  SetStateAction,
  Dispatch,
} from 'react';

interface CoookeBannerContext {
  cookieBannerVisible: boolean;
  setCookieBannerVisible: Dispatch<SetStateAction<boolean>>;
}

const CookieBannerContext = createContext<CoookeBannerContext>({
  cookieBannerVisible: false,
  setCookieBannerVisible: () => null,
});

export const CookieBannerProvider: React.ComponentType<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [cookieBannerVisible, setCookieBannerVisible] = useState(false);

  return (
    <CookieBannerContext.Provider
      value={{ cookieBannerVisible, setCookieBannerVisible }}
    >
      {children}
    </CookieBannerContext.Provider>
  );
};

export const useCookieBanner = () => useContext(CookieBannerContext);
