export const FormatUnorderedListIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      fill="currentColor"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3 7.875C2.4 7.875 1.875 8.4 1.875 9C1.875 9.6 2.4 10.125 3 10.125C3.6 10.125 4.125 9.6 4.125 9C4.125 8.4 3.6 7.875 3 7.875ZM3 4.125C2.4 4.125 1.875 4.65 1.875 5.25C1.875 5.85 2.4 6.375 3 6.375C3.6 6.375 4.125 5.85 4.125 5.25C4.125 4.65 3.6 4.125 3 4.125ZM3 11.625C2.4 11.625 1.875 12.15 1.875 12.75C1.875 13.35 2.4 13.875 3 13.875C3.6 13.875 4.125 13.35 4.125 12.75C4.125 12.15 3.6 11.625 3 11.625ZM5.625 4.5V6H16.125V4.5H5.625ZM5.625 13.5H16.125V12H5.625V13.5ZM5.625 9.75H16.125V8.25H5.625V9.75Z" />
    </svg>
  );
};

export default FormatUnorderedListIcon;
