// these companies have low domain authorities so we don't want backlinks from them
const badDomains = new Set([
  'mvl',
  'knm',
  'hvy',
  'lvh',
  'pwn',
  'she',
  'rad',
  'bsx',
  'env',
  'chm',
  'akn',
  'lgm',
  'ssh',
  'rac',
  'atc',
  'ajq',
  'brk',
  'htg',
  'dre',
  'jll',
  'emu',
  'gsn',
  'shg',
  'dou',
  'ams',
  '3da',
  'dcc',
  'wa8',
  'agn',
  'caz',
  'hlx',
  'sp3',
  'ipt',
  'meu',
  'dmm',
  'esr',
  'gmn',
  'yrl',
  'tso',
  'mxr',
  'tem',
  'oau',
  'wzr',
  'aqd',
  'bre',
  'tgm',
  'fg1',
  'nnl',
  'fos',
  'dnk',
  'sw1',
  'tryb-l',
  'ree-l',
  'n4p-l',
  'alk-l',
  'kash-aq',
  'ana-aq',
]);

export const maybeAddRelNofollow = (marketListingKey: string) => {
  if (badDomains.has(marketListingKey)) {
    return 'nofollow';
  }
  return '';
};
