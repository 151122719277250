import Typography from '../typography';
import { getCharCount } from './helpers';
import type { EditorState } from 'draft-js';

interface Props {
  charLimit: number;
  editorState: EditorState;
}

const CharLimit: React.ComponentType<Props> = ({ charLimit, editorState }) => {
  const isOverLimit = getCharCount(editorState) > charLimit;

  return (
    <Typography className={isOverLimit ? 'text-status-red' : 'text-text-grey'}>
      {isOverLimit
        ? 'You are over the character limit.'
        : `${charLimit} character limit.`}
    </Typography>
  );
};

export default CharLimit;
