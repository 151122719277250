import React, { useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import ReactDatePicker from 'react-datepicker';

type Maybe<T> = T | null;
interface Props {
  endDate: Maybe<Date>;
  maxDate?: Date;
  minDate?: Date;
  setEndDate: (date: Maybe<Date>) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setStartDate: (date: Maybe<Date>) => void;
  startDate: Maybe<Date>;
}

export const DateRangePicker: React.FC<Props> = ({
  endDate,
  maxDate,
  minDate,
  setEndDate,
  setOpen,
  setStartDate,
  startDate,
}) => {
  const [rangePickerEndDate, setRangePickerEndDate] =
    useState<Maybe<Date>>(startDate);
  const [rangePickerStartDate, setRangePickerStartDate] =
    useState<Maybe<Date>>(endDate);

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    setRangePickerStartDate(start);
    setRangePickerEndDate(end);

    if (start && end) {
      setStartDate(start);
      setEndDate(end);
      setOpen(false);
    }
  };

  useEffect(() => {
    setRangePickerStartDate(startDate);
    setRangePickerEndDate(endDate);
  }, [startDate, endDate]);

  // there is a very similar DatePicker UI being used on Athena /investors/search/contacts/[id]#activity-log
  // This one only seems to be getting used on MarketData, there's a more basic datepicker getting used in filters modal
  return (
    <div className="date-range-picker">
      <div className="date-range-inputs">
        <ReactDatePicker
          dateFormat="d/M/yyyy"
          placeholderText="d/m/yyyy"
          popperClassName="hidden"
          selected={startDate}
          strictParsing={true}
          onChange={(date) => setStartDate(date)}
        />
        <span className="flex-1 text-center text-hubs-secondary">to</span>
        <ReactDatePicker
          dateFormat="d/M/yyyy"
          placeholderText="d/m/yyyy"
          popperClassName="hidden"
          selected={endDate}
          strictParsing={true}
          onChange={(date) => setEndDate(date)}
        />
      </div>
      <ReactDatePicker
        inline
        selectsRange
        showMonthDropdown
        showYearDropdown
        dateFormat="dd/MM/yyyy"
        dateFormatCalendar="MMMM yyyy"
        dropdownMode="select"
        endDate={rangePickerEndDate}
        maxDate={maxDate || new Date()}
        minDate={minDate || new Date(1980, 1, 1, 0, 0, 0, 0)}
        nextMonthButtonLabel={<ChevronRightIcon />}
        previousMonthButtonLabel={<ChevronLeftIcon />}
        startDate={rangePickerStartDate}
        onChange={onChange}
      />
    </div>
  );
};

export default DateRangePicker;
