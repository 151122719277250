import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';

interface PlainTextProps {
  allowShowMore?: boolean;
  className?: string;
  content?: string;
  maxLines?: number;
}

export const PlainText: React.ComponentType<PlainTextProps> = ({
  allowShowMore = false,
  className = '',
  content,
  maxLines = 5,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [wasOverflowing, setWasOverflowing] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const lineClampedRef = useRef<HTMLDivElement | null>(null);
  const lineHeight = 24;

  useEffect(() => {
    const { scrollHeight } = lineClampedRef.current || {};
    return setIsOverflowing(
      scrollHeight ? scrollHeight > lineHeight * maxLines : false
    );
  }, [lineClampedRef, maxLines]);

  useEffect(() => {
    if (expanded) {
      setWasOverflowing(true);
    }
  }, [expanded]);

  const descriptionRef = useRef<HTMLDivElement>(null);

  const descriptionHTML = content ? content.replace(/\n/g, '<br />') : '';

  return (
    <div className={className}>
      {allowShowMore ? (
        <>
          <div
            dangerouslySetInnerHTML={{ __html: descriptionHTML }}
            ref={lineClampedRef}
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className={clsx(
              isOverflowing && !expanded ? `line-clamp-${maxLines}` : ''
            )}
          />
          {wasOverflowing || (isOverflowing && !expanded) ? (
            <button
              className="inline-flex items-center gap-2 font-body text-company-primary no-underline"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? 'Show less' : 'Show more'}
            </button>
          ) : null}
        </>
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: descriptionHTML }}
          ref={descriptionRef}
        />
      )}
    </div>
  );
};
