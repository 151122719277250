import React, { useMemo, useCallback, useRef } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { type Block } from '@blocknote/core';
import {
  ArrowsPointingOutIcon,
  ArrowsPointingInIcon,
} from '@heroicons/react-v2/24/outline';
import { CheckIcon } from '@heroicons/react/solid';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useRouter } from 'next/router';
import {
  GetWebinarDataQuery,
  useRegisterForWebinarMutation,
  useViewWebinarRecordingMutation,
} from '../../../apollo/generated';
import {
  BlockHeading,
  HubButton,
  Typography,
  Badge,
  RenderBlockNote,
  WebinarDocuments,
} from '../../../index';
import { useAlert } from '../contexts/alert-context';
import { setReturnToCookie } from '../utils';
import { WebinarBackgroundContainer } from './webinar-background-container';
import { WebinarQuestions } from './webinar-questions';
import {
  getTimeRemaining,
  useTimeRemaining,
  useFullscreen,
  formatDuration,
  formatTimeRemaining,
} from './webinar-util';

dayjs.extend(utc);
dayjs.extend(timezone);

interface WebinarViewerProps {
  attendee?: NonNullable<
    NonNullable<GetWebinarDataQuery['webinarData']>['attendee']
  > | null;
  client?: ApolloClient<NormalizedCacheObject>;
  isLoggedIn?: boolean;
  name?: string;
  roomCodeOverride?: string | undefined;
  webinar: NonNullable<
    NonNullable<GetWebinarDataQuery['webinarData']>['webinar']
  >;
}

export const WebinarViewer: React.FC<WebinarViewerProps> = ({
  attendee,
  client,
  isLoggedIn = false,
  roomCodeOverride,
  webinar,
}) => {
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const { isFullscreen, toggleFullscreen } = useFullscreen(videoContainerRef);

  const timeRemaining = useTimeRemaining(
    webinar.startTime || '',
    webinar.timezone
  );

  const buildUrl = useMemo(() => {
    const baseUrl = `https://${
      process.env.NEXT_PUBLIC_HMS_SUBDOMAIN
    }.app.100ms.live/meeting/${roomCodeOverride || webinar.hmsViewerRoomCode}`;
    const params = new URLSearchParams();

    if (attendee) {
      params.append('userId', attendee.id);
    }

    const queryString = params.toString();
    return queryString ? `${baseUrl}?${queryString}` : baseUrl;
  }, [attendee, roomCodeOverride, webinar.hmsViewerRoomCode]);

  const formattedTimes = useMemo(() => {
    const startTime = dayjs
      .tz(
        webinar.startedBroadcastingAt || webinar.startTime,
        webinar.timezone || 'UTC'
      )
      .local();
    const endTime = dayjs
      .tz(
        webinar.stoppedBroadcastingAt || webinar.endTime,
        webinar.timezone || 'UTC'
      )
      .local();
    const durationHours = endTime.diff(startTime, 'hour');
    const durationMinutes = endTime.diff(startTime, 'minute') % 60;

    return {
      durationHours,
      durationMinutes,
      endTime,
      startTime,
    };
  }, [
    webinar.startTime,
    webinar.endTime,
    webinar.timezone,
    webinar.startedBroadcastingAt,
    webinar.stoppedBroadcastingAt,
  ]);

  return (
    <div className="flex flex-col">
      {webinar.state === 'completed' && webinar.publishedRecordingUrl ? (
        isLoggedIn && attendee && attendee.viewedRecordingAt ? (
          <div ref={videoContainerRef} className="relative h-[650px] w-full">
            <video
              controls
              className="h-full w-full"
              src={webinar.publishedRecordingUrl}
            >
              Your browser does not support the video tag.
            </video>
            <button
              aria-label={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
              className="absolute right-4 top-4 rounded-full bg-black/50 p-2 text-white hover:bg-black/70"
              onClick={toggleFullscreen}
            >
              {isFullscreen ? (
                <ArrowsPointingInIcon className="h-5 w-5" />
              ) : (
                <ArrowsPointingOutIcon className="h-5 w-5" />
              )}
            </button>
          </div>
        ) : (
          <WebinarPlaceholderPanel
            attendee={attendee}
            isLoggedIn={isLoggedIn}
            timeRemaining={timeRemaining}
            webinar={webinar}
          />
        )
      ) : (webinar.state === 'live' && isLoggedIn && attendee) ||
        roomCodeOverride ? (
        <div ref={videoContainerRef} className="relative h-[650px] w-full">
          <iframe
            allow="camera *;microphone *;display-capture *"
            className="h-full w-full border-0"
            src={buildUrl}
            title={webinar.title || 'Webinar'}
          ></iframe>
          <button
            aria-label={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
            className="absolute right-4 top-4 rounded-full bg-black/50 p-2 text-white hover:bg-black/70"
            onClick={toggleFullscreen}
          >
            {isFullscreen ? (
              <ArrowsPointingInIcon className="h-5 w-5" />
            ) : (
              <ArrowsPointingOutIcon className="h-5 w-5" />
            )}
          </button>
        </div>
      ) : (
        <WebinarPlaceholderPanel
          attendee={attendee}
          isLoggedIn={isLoggedIn}
          timeRemaining={timeRemaining}
          webinar={webinar}
        />
      )}
      <div className="mx-auto w-full max-w-screen-xl px-4 py-8 sm:px-6">
        <div className="mb-4 mt-2 flex items-center gap-2 text-gray-500">
          {webinar.state === 'live' && (
            <Badge state="positive" title="Live" variant="primary" />
          )}
          {webinar.state === 'completed' && (
            <Badge state="neutral" title="Ended" variant="secondary" />
          )}
          <Typography className="flex items-center" variant="body-small">
            {formattedTimes.startTime.format('DD/MM/YYYY')}
          </Typography>
          <Typography className="flex items-center" variant="body-small">
            {formattedTimes.startTime.format('h:mma')} -{' '}
            {formattedTimes.endTime.format('h:mma')} (
            {formattedTimes.durationHours} hour
            {formattedTimes.durationHours > 1 ? 's' : ''}
            {formattedTimes.durationMinutes > 0 && (
              <>
                , {formattedTimes.durationMinutes} minute
                {formattedTimes.durationMinutes > 1 ? 's' : ''}
              </>
            )}
            )
          </Typography>
        </div>
        <BlockHeading>{webinar.title}</BlockHeading>
        {!!webinar.summary && (
          <RenderBlockNote
            content={(webinar.summary as { content: Block[] }).content}
          />
        )}
        <WebinarDocuments
          canDownload={
            ['scheduled', 'live'].includes(webinar.state || '')
              ? !!attendee
              : true
          }
          documents={webinar.documents}
        />
      </div>
      {webinar.allowPreWebinarComments ? (
        <WebinarQuestions
          client={client}
          hasStarted={['live', 'completed'].includes(webinar.state || '')}
          isLoggedIn={isLoggedIn}
          questions={attendee?.questions}
          webinar={webinar}
        />
      ) : null}
    </div>
  );
};

const WebinarPlaceholderPanel: React.FC<{
  attendee?: WebinarViewerProps['attendee'];
  isLoggedIn: boolean;
  timeRemaining: ReturnType<typeof getTimeRemaining>;
  webinar: WebinarViewerProps['webinar'];
}> = ({ attendee, isLoggedIn, timeRemaining, webinar }) => {
  const [registerMutation] = useRegisterForWebinarMutation({
    refetchQueries: ['GetWebinarData'],
  });
  const [viewRecordingMutation] = useViewWebinarRecordingMutation({
    refetchQueries: ['GetWebinarData'],
  });
  const { formatAndShowError, showAlert } = useAlert();
  const router = useRouter();

  const handleRegister = useCallback(async () => {
    try {
      if (webinar.state === 'completed' && webinar.publishedRecordingUrl) {
        await viewRecordingMutation({ variables: { webinarId: webinar.id } });
        showAlert({
          description: 'Recording access granted',
          variant: 'success',
        });
      } else {
        await registerMutation({ variables: { webinarId: webinar.id } });
        showAlert({
          description: 'Successfully registered for the webinar',
          variant: 'success',
        });
      }
    } catch (error) {
      formatAndShowError(error);
    }
  }, [
    registerMutation,
    viewRecordingMutation,
    webinar.id,
    webinar.state,
    webinar.publishedRecordingUrl,
    showAlert,
    formatAndShowError,
  ]);

  if (webinar.state === 'completed') {
    const finishedTime = dayjs.tz(
      webinar.stoppedBroadcastingAt || webinar.endTime,
      webinar.timezone || 'UTC'
    );

    return (
      <WebinarBackgroundContainer webinar={webinar}>
        <div className="z-10 flex flex-col items-center justify-center">
          <Typography variant="display-medium">Webinar finished</Typography>
          {webinar.publishedRecordingUrl ? (
            isLoggedIn ? (
              !attendee?.viewedRecordingAt ? (
                <>
                  <Typography className="mt-4" variant="body-large">
                    A recording of this webinar is available to watch.
                  </Typography>
                  <HubButton
                    isAccent
                    className="mt-10"
                    label="Watch recording"
                    onClick={handleRegister}
                  />
                </>
              ) : null
            ) : (
              <>
                <Typography className="mt-4" variant="body-large">
                  To view the recording of this webinar please either sign in or
                  sign up.
                </Typography>
                <div className="mt-10 flex space-x-4">
                  <HubButton
                    isAccent
                    label="Log in"
                    url="/auth/signin"
                    onClick={(e) => {
                      e.preventDefault();
                      setReturnToCookie(router.asPath);
                      router.push('/auth/signin');
                    }}
                  />
                  <HubButton
                    isAccent
                    label="Sign up"
                    url="/auth/signup"
                    onClick={(e) => {
                      e.preventDefault();
                      setReturnToCookie(router.asPath);
                      router.push('/auth/signup');
                    }}
                  />
                </div>
              </>
            )
          ) : (
            <Typography className="mt-4" variant="body-large">
              This webinar ended {finishedTime.fromNow()}.
            </Typography>
          )}
        </div>
      </WebinarBackgroundContainer>
    );
  }

  const remainingTime =
    webinar.state === 'live'
      ? dayjs
          .tz(webinar.endTime, webinar.timezone || 'UTC')
          .diff(dayjs().tz(webinar.timezone || 'UTC'), 'second')
      : timeRemaining.total / 1000;

  return (
    <WebinarBackgroundContainer webinar={webinar}>
      <div className="z-10 flex flex-col items-center justify-center">
        <Typography variant="display-medium">
          {webinar.state === 'live'
            ? 'Webinar is live now'
            : dayjs()
                .tz(webinar.timezone || 'UTC')
                .isAfter(dayjs.tz(webinar.startTime, webinar.timezone || 'UTC'))
            ? 'Webinar will start soon'
            : 'Webinar starting in'}
        </Typography>
        <Typography variant="display-small">
          {webinar.state === 'scheduled' &&
            `${formatTimeRemaining(remainingTime, 'Hold tight!')}`}
          {webinar.state === 'live' &&
            `${formatTimeRemaining(remainingTime)} remaining`}
        </Typography>
        <div className="mt-4 flex items-center gap-2">
          <Typography className="flex items-center" variant="body-regular">
            {dayjs
              .tz(webinar.startTime, webinar.timezone || 'UTC')
              .local()
              .format('DD/MM/YYYY')}
          </Typography>
          <Typography className="flex items-center" variant="body-regular">
            {dayjs
              .tz(webinar.startTime, webinar.timezone || 'UTC')
              .local()
              .format('h:mma')}{' '}
            -{' '}
            {dayjs
              .tz(webinar.endTime, webinar.timezone || 'UTC')
              .local()
              .format('h:mma')}{' '}
            {formatDuration(
              dayjs
                .tz(webinar.endTime, webinar.timezone || 'UTC')
                .diff(
                  dayjs.tz(webinar.startTime, webinar.timezone || 'UTC'),
                  'hour'
                ),
              dayjs
                .tz(webinar.endTime, webinar.timezone || 'UTC')
                .diff(
                  dayjs.tz(webinar.startTime, webinar.timezone || 'UTC'),
                  'minute'
                ) % 60
            )}
          </Typography>
        </div>
        {isLoggedIn ? (
          attendee ? (
            <div className="mt-10 flex flex-col items-center gap-3">
              <div className="mb-4 flex items-center">
                <div className="mr-2 rounded-full bg-white p-1">
                  <CheckIcon className="h-5 w-5 text-company-primary" />
                </div>
                <Typography variant="body-large">
                  You are registered to attend
                </Typography>
              </div>
              <AddToCalendarButton
                endDate={dayjs
                  .tz(webinar.endTime, webinar.timezone || 'UTC')
                  .format('YYYY-MM-DD')}
                endTime={dayjs
                  .tz(webinar.endTime, webinar.timezone || 'UTC')
                  .format('HH:mm')}
                location="Online"
                name={webinar.title || 'Webinar'}
                options={['Apple', 'Google', 'iCal', 'Outlook.com', 'Yahoo']}
                startDate={dayjs
                  .tz(webinar.startTime, webinar.timezone || 'UTC')
                  .format('YYYY-MM-DD')}
                startTime={dayjs
                  .tz(webinar.startTime, webinar.timezone || 'UTC')
                  .format('HH:mm')}
                styleDark="--font: inherit;"
                styleLight=" --font: inherit;"
                timeZone={webinar.timezone || 'UTC'}
              />
            </div>
          ) : (
            <HubButton
              isAccent
              className="mt-10"
              label={
                webinar.state === 'live' ? 'Join webinar' : 'Register to attend'
              }
              onClick={handleRegister}
            />
          )
        ) : (
          <div className="mt-10 flex flex-col items-center gap-5">
            <Typography variant="body-large">
              Log in or sign up to view this webinar
            </Typography>
            <div className="flex space-x-4">
              <HubButton
                isAccent
                label="Log in"
                url="/auth/signin"
                onClick={(e) => {
                  e.preventDefault();
                  setReturnToCookie(router.asPath);
                  router.push('/auth/signin');
                }}
              />
              <HubButton
                isAccent
                label="Sign up"
                url="/auth/signup"
                onClick={(e) => {
                  e.preventDefault();
                  setReturnToCookie(router.asPath);
                  router.push('/auth/signup');
                }}
              />
            </div>
          </div>
        )}
      </div>
    </WebinarBackgroundContainer>
  );
};
