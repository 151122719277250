import React from 'react';
import { CldImage, CldImageProps } from 'next-cloudinary';

const CloudinaryImage: React.ComponentType<CldImageProps> = (props) => {
  return (
    <CldImage
      config={{
        cloud: {
          cloudName:
            props.src && props.src.includes('dummy_images_for_blocks')
              ? process.env.NEXT_PUBLIC_DUMMY_DATA_CLOUDINARY_CLOUD_NAME
              : process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
        },
      }}
      {...props}
    />
  );
};

export default CloudinaryImage;
