export const InvestorHubIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      fill="currentColor"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.356 0.384066C9.05684 0.381559 7.7699 0.635155 6.56879 1.13035C5.36768 1.62555 4.27595 2.35265 3.35603 3.27006C1.45782 5.16592 0.389018 7.73723 0.384033 10.42V19.62H9.64403C10.9432 19.6225 12.2302 19.3689 13.4313 18.8737C14.6324 18.3785 15.7241 17.6514 16.644 16.734C18.5428 14.8369 19.6117 12.2641 19.616 9.58004V0.380066L10.356 0.384066ZM1.71203 10.42C1.71067 9.53074 1.84557 8.64648 2.11203 7.79804H11.31L1.71003 17.458L1.71203 10.42ZM12.34 17.842C11.4717 18.1303 10.5629 18.2781 9.64803 18.28H2.77003L12.34 8.65404V17.842ZM18.288 2.66406V9.58004C18.2895 11.1625 17.8603 12.7156 17.0464 14.0727C16.2325 15.4299 15.0647 16.54 13.668 17.284V6.46205H2.66803C3.38236 5.03568 4.48019 3.8367 5.83824 2.99975C7.1963 2.1628 8.76079 1.72104 10.356 1.72406H18.288V2.66406Z" />
    </svg>
  );
};

export default InvestorHubIcon;
