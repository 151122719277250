import { SVGProps } from 'react';

export const ActionUpIcon: React.FC<SVGProps<SVGElement>> = ({
  className,
  height,
  width,
}) => {
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.40055 15H16.3995C16.4786 14.9982 16.5554 14.9735 16.6208 14.929C16.6862 14.8844 16.7373 14.8219 16.768 14.749C16.7986 14.676 16.8075 14.5958 16.7936 14.5179C16.7796 14.44 16.7435 14.3678 16.6894 14.31L12.69 10.2002C12.6519 10.1621 12.6067 10.1319 12.5569 10.1112C12.5072 10.0906 12.4539 10.08 12.4 10.08C12.3461 10.08 12.2928 10.0906 12.2431 10.1112C12.1933 10.1319 12.1481 10.1621 12.11 10.2002L8.11059 14.31C8.05653 14.3678 8.02035 14.44 8.00641 14.5179C7.99247 14.5958 8.00137 14.676 8.03202 14.749C8.06268 14.8219 8.11378 14.8844 8.17918 14.929C8.24457 14.9735 8.32145 14.9982 8.40055 15Z" />
    </svg>
  );
};

export default ActionUpIcon;
