import {
  DocumentChartBarIcon,
  PresentationChartLineIcon,
} from '@heroicons/react-v2/24/solid';
import Image from 'next/image';
import { PlayButtonIcon } from '../../icons';

interface AnnouncementThumbnailProps {
  imageUrl?: string;
  socialVideoUrl?: string;
  subtypes?: (string | null)[];
  title: string;
  videoUrl?: string;
}

const AnnouncementThumbnail: React.FC<AnnouncementThumbnailProps> = ({
  imageUrl,
  socialVideoUrl,
  subtypes,
  title,
  videoUrl,
}) => {
  const Icon = subtypes?.includes('03026')
    ? PresentationChartLineIcon
    : subtypes?.includes('03001')
    ? DocumentChartBarIcon
    : undefined;
  return (
    <div className="relative h-full w-full pt-[56%]">
      {videoUrl || socialVideoUrl ? (
        <div className="absolute inset-0">
          {videoUrl ? (
            <video
              className="pointer-events-none absolute left-1/2 top-1/2 z-10 h-full w-full -translate-x-1/2 -translate-y-1/2"
              src={`${videoUrl}?autoplay=0&showinfo=0&controls=0`}
            />
          ) : null}
          {socialVideoUrl ? (
            <iframe
              className="pointer-events-none absolute left-1/2 top-1/2 z-10 h-full w-full -translate-x-1/2 -translate-y-1/2"
              src={`${socialVideoUrl}?autoplay=0&showinfo=0&controls=0`}
              title={title}
            />
          ) : null}
          <div className="absolute left-1/2 top-1/2 z-10 h-10 w-10 -translate-x-1/2 -translate-y-1/2">
            <PlayButtonIcon className="h-10 w-10 fill-inherit" />
          </div>
        </div>
      ) : imageUrl ? (
        <>
          <div className="absolute inset-0 z-10 bg-black opacity-5" />
          <Image
            alt={title}
            className="absolute left-0 top-0 h-auto w-full object-cover"
            height={200}
            src={imageUrl}
            width={300}
          />
        </>
      ) : Icon ? (
        <div className="absolute left-[50%] top-[50%] flex h-[52px] w-[52px] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full ">
          <Icon className="h-8 w-8 text-gray-300" />
        </div>
      ) : null}
    </div>
  );
};

export default AnnouncementThumbnail;
