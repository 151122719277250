// Modules
import { XCircleIcon } from '@heroicons/react/outline';
import Typography from './typography';

export interface TagProps {
  onDelete?: () => void;
  title: string;
}

export const Tag: React.ComponentType<TagProps> = ({ onDelete, title }) => {
  return (
    <div className="inline-flex items-center justify-center space-x-2 rounded bg-primary-grey-dark/10 p-2">
      <Typography component="span" variant="badge">
        {title}
      </Typography>
      {typeof onDelete !== 'undefined' && (
        <button type="button" onClick={onDelete}>
          <XCircleIcon className="h-6 w-6" />
        </button>
      )}
    </div>
  );
};

export default Tag;
