import { forwardRef } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import Typography from './typography';

export interface TextAreaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  dark?: boolean;
  error?: boolean;
  helperText?: string;
  label?: string;
  optional?: boolean;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    { className, dark, error, helperText, id, label, optional, ...props },
    ref
  ) => {
    return (
      <div>
        {!!label && (
          <div className="mb-1 flex items-center justify-between space-x-2">
            <label htmlFor={id}>
              <Typography
                className={clsx('dark:text-white', dark && 'text-white')}
                component="span"
                variant="button"
              >
                {label}
              </Typography>
            </label>
            {optional && (
              <Typography
                className={clsx(
                  'dark:text-white',
                  dark ? 'text-white' : 'text-text-grey'
                )}
              >
                Optional
              </Typography>
            )}
          </div>
        )}
        <div className="relative">
          <textarea
            {...props}
            ref={ref}
            className={clsx('input', error && 'input-error pr-11', className)}
            id={id}
          />
          {error && (
            <ExclamationCircleIcon className="absolute right-3 top-1/2 h-5 w-5 -translate-y-1/2 text-status-red" />
          )}
        </div>
        {!!helperText && (
          <Typography
            className={clsx(
              'mt-2',
              error
                ? 'text-status-red'
                : clsx(
                    'dark:text-white',
                    dark ? 'text-white' : 'text-text-grey'
                  )
            )}
          >
            {helperText}
          </Typography>
        )}
      </div>
    );
  }
);

export default TextArea;
