export const FormatStrikethroughIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      fill="currentColor"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 9.40684H17V10.7402H13.1193C13.3298 11.1821 13.4361 11.6666 13.4299 12.1561C13.4424 12.6205 13.3427 13.0811 13.1393 13.4988C12.936 13.9166 12.6349 14.2791 12.2617 14.5558C11.3626 15.1884 10.278 15.5027 9.18001 15.4487C8.37774 15.4541 7.58329 15.2908 6.84814 14.9696C6.17678 14.691 5.59482 14.2335 5.1658 13.6467C4.77077 13.0866 4.56233 12.4162 4.5701 11.7308V11.63H4.82541V11.629H5.12896V11.63H6.35111V11.7307C6.33719 12.0431 6.39895 12.3542 6.53114 12.6375C6.66333 12.9208 6.86204 13.168 7.11034 13.358C7.71248 13.7867 8.44178 13.9995 9.17999 13.9618C9.83167 14.0044 10.4795 13.834 11.0259 13.4763C11.2285 13.3265 11.3912 13.1294 11.4999 12.9021C11.6086 12.6748 11.6599 12.4244 11.6493 12.1727C11.6635 11.9295 11.6185 11.6866 11.5184 11.4645C11.4182 11.2425 11.2658 11.048 11.0742 10.8977C10.9956 10.8408 10.9141 10.7882 10.8298 10.7401H1V9.40684ZM12.862 4.83516C12.4873 4.25295 11.9574 3.78697 11.3321 3.48973C10.635 3.15765 9.87047 2.99148 9.09842 3.00422C8.02186 2.95802 6.9634 3.29226 6.10861 3.94834C5.74165 4.23176 5.44563 4.59665 5.24398 5.01418C5.04233 5.4317 4.94056 5.89041 4.94672 6.35404C4.94361 6.79342 5.04141 7.22764 5.23259 7.62325H7.54075C7.46663 7.57556 7.37597 7.52924 7.31613 7.48046C7.13528 7.34705 6.98846 7.17284 6.88763 6.97199C6.78679 6.77114 6.73479 6.54932 6.73584 6.32459C6.72243 6.06804 6.77104 5.81205 6.87756 5.57828C6.98408 5.34451 7.14537 5.13986 7.34777 4.98165C7.85985 4.62651 8.47634 4.45359 9.09842 4.4906C9.7748 4.4476 10.4427 4.65969 10.9704 5.085C11.1911 5.29282 11.3639 5.54616 11.4769 5.82745C11.5899 6.10874 11.6403 6.41124 11.6246 6.71396V6.81477H13.4052V6.71396C13.4082 6.04854 13.2196 5.39631 12.862 4.83516Z" />
    </svg>
  );
};

export default FormatStrikethroughIcon;
