import React, { useCallback, useRef, useState } from 'react';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createLinkPlugin from '@draft-js-plugins/anchor';
import {
  AlignTextCenterButton,
  AlignTextLeftButton,
  AlignTextRightButton,
} from '@draft-js-plugins/buttons';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createFocusPlugin from '@draft-js-plugins/focus';
import createImagePlugin from '@draft-js-plugins/image';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createTextAlignmentPlugin from '@draft-js-plugins/text-alignment';
import createVideoPlugin from '@draft-js-plugins/video';
import clsx from 'clsx';
import {
  ContentBlock,
  DraftHandleValue,
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
} from 'draft-js';
import Link, { findLinkEntities } from '../editors/link';
import {
  FormatTextIcon,
  ImageIcon,
  OpenFullscreenIcon,
  VideoIcon,
} from '../icons';
import Typography from '../typography';
import { useEditor, withEditorContext } from './context';
import Counter, { CounterProps } from './counter';
import styles from './index.module.css';
import InlineStyles from './inline-styles';
import LinkFormModal from './link-form-modal';
import ListStyles from './list-styles';
import TextStyleMenu from './text-style-menu';
import Video from './video';

const alignmentPlugin = createAlignmentPlugin();

const focusPlugin = createFocusPlugin();

const resizeablePlugin = createResizeablePlugin();

const imagePlugin = createImagePlugin({
  decorator: composeDecorators(
    resizeablePlugin.decorator,
    alignmentPlugin.decorator,
    focusPlugin.decorator
  ),
});

const linkifyPlugin = createLinkifyPlugin({
  rel: 'noopener noreferrer',
  target: '_blank',
  theme: {
    link: 'text-primary-green-dark underline',
  },
});

const linkPlugin = createLinkPlugin({
  theme: {
    input: 'input',
    inputInvalid: 'input-error',
    link: 'text-primary-green-dark underline',
  },
});

const textAlignmentPlugin = createTextAlignmentPlugin({
  theme: {
    alignmentStyles: {
      draftCenter: styles['text-align-center'],
      draftLeft: styles['text-align-left'],
      draftRight: styles['text-align-right'],
    },
  },
});

const videoPlugin = createVideoPlugin({
  decorator: composeDecorators(
    focusPlugin.decorator,
    resizeablePlugin.decorator
  ),
  videoComponent: Video,
});

const plugins = [
  alignmentPlugin,
  focusPlugin,
  imagePlugin,
  linkifyPlugin,
  linkPlugin,
  resizeablePlugin,
  textAlignmentPlugin,
  videoPlugin,
];

interface OverrideContentProps {
  getEditorState: () => EditorState;
  onOverrideContent: (
    content: React.ComponentType<unknown> | undefined
  ) => void;
  setEditorState: (editorState: EditorState) => void;
}

interface Props {
  compact?: boolean;
  content?: string;
  defaultExpanded?: boolean;
  hideModalButton?: boolean;
  limit?: CounterProps;
  onChange?: (value: string) => void;
  onUploadAsset?: (asset: File) => Promise<string>;
  readOnly?: boolean;
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const superRichTextEditorDecorators = [
  { component: Link, strategy: findLinkEntities },
];

export const SuperRichTextEditor = withEditorContext<Props>(
  ({
    compact,
    hideModalButton,
    limit,
    onUploadAsset,
    readOnly,
    setModalOpen,
  }) => {
    const {
      editorState,
      errorMessage,
      formatAndShowError,
      isExpanded,
      onBlur,
      setEditorState,
      setExpanded,
    } = useEditor();

    const imagePickerRef = useRef<HTMLInputElement>(null);
    const storedEditorState = useRef(editorState);
    const videoPickerRef = useRef<HTMLInputElement>(null);

    const [AddLinkForm, setAddLinkForm] = useState<
      React.ComponentType<OverrideContentProps> | undefined
    >();

    const blockStyleFn = useCallback((block: ContentBlock) => {
      switch (block.getType()) {
        case 'header-three':
          return 'typography-subtitle-1';

        case 'header-six':
          return 'typography-subtitle-2';

        default:
          return 'typography-body-regular';
      }
    }, []);

    const handleKeyCommand = useCallback<
      (
        command: string,
        editorState: EditorState,
        eventTimeStamp: number
      ) => DraftHandleValue
    >(
      (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
          setEditorState(newState);

          return 'handled';
        }

        return 'not-handled';
      },
      [setEditorState]
    );

    const keyBindingFn = useCallback(
      // eslint-disable-next-line
      // @ts-ignore
      (event) => {
        if (event.key === 'Tab') {
          setEditorState((prevState) => RichUtils.onTab(event, prevState, 4));

          return;
        }

        return getDefaultKeyBinding(event);
      },
      [setEditorState]
    );

    const onSelectImage = useCallback<
      React.ChangeEventHandler<HTMLInputElement>
    >(
      (e) => {
        if (e.target.files?.length && typeof onUploadAsset !== 'undefined') {
          const file = e.target.files[0];

          if (file.size > 5000000) {
            formatAndShowError('Image must be less than 5MB.');
          } else {
            onUploadAsset(e.target.files[0])
              .then((url) => {
                setEditorState(
                  imagePlugin.addImage(storedEditorState.current, url, {})
                );
              })
              .catch((error) => {
                formatAndShowError(error);
              });
          }
        } else {
          formatAndShowError('Unable to upload image.');
        }
      },
      [formatAndShowError, onUploadAsset, setEditorState]
    );

    const onSelectVideo = useCallback<
      React.ChangeEventHandler<HTMLInputElement>
    >(
      (e) => {
        if (e.target.files?.length && typeof onUploadAsset !== 'undefined') {
          onUploadAsset(e.target.files[0])
            .then((url) => {
              setEditorState(
                videoPlugin.addVideo(storedEditorState.current, { src: url })
              );
            })
            .catch((error) => {
              formatAndShowError(error);
            });
        } else {
          formatAndShowError('Unable to upload video.');
        }
      },
      [formatAndShowError, onUploadAsset, setEditorState]
    );

    const onMouseDown = useCallback<React.MouseEventHandler<HTMLDivElement>>(
      (e) => {
        e.preventDefault();
        storedEditorState.current = editorState;
      },
      [editorState]
    );

    if (readOnly) {
      return (
        <Editor
          readOnly
          spellCheck
          blockStyleFn={blockStyleFn}
          decorators={superRichTextEditorDecorators}
          editorKey="super-rich-editor"
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={keyBindingFn}
          plugins={plugins}
          onChange={setEditorState}
        />
      );
    }

    return (
      <div className="rounded-lg border border-secondary-grey-light bg-hubs-fixed-white">
        <div className="flex items-center gap-2 p-2">
          <TextStyleMenu />
          <div className="h-[18px] w-px bg-secondary-grey-light-2" />
          <button
            className={clsx(
              'flex h-6 w-6 items-center justify-center rounded',
              isExpanded ? 'bg-secondary-grey-light' : 'bg-transparent'
            )}
            type="button"
            onClick={() => setExpanded((p) => !p)}
          >
            <FormatTextIcon
              className={
                isExpanded
                  ? 'fill-text-main stroke-text-main'
                  : 'fill-text-secondary stroke-text-secondary'
              }
            />
          </button>
          <div className="h-[18px] w-px bg-secondary-grey-light-2" />
          <linkPlugin.LinkButton
            theme={{
              active: 'child:fill-text-main',
              button: 'child:w-[18px] child:h-[18px] child:fill-text-main',
              buttonWrapper: 'flex',
            }}
            onOverrideContent={(content) => setAddLinkForm(() => content)}
          />
          {onUploadAsset ? (
            <>
              <div className="flex" onMouseDown={onMouseDown}>
                <button
                  type="button"
                  onClick={() => imagePickerRef.current?.click()}
                >
                  <ImageIcon className="fill-text-main" />
                </button>
                <input
                  ref={imagePickerRef}
                  hidden
                  accept="image/*"
                  id="image-picker"
                  type="file"
                  value=""
                  onChange={onSelectImage}
                />
              </div>
              <div className="flex" onMouseDown={onMouseDown}>
                <button
                  type="button"
                  onClick={() => videoPickerRef.current?.click()}
                >
                  <VideoIcon className="fill-text-main" />
                </button>
                <input
                  ref={videoPickerRef}
                  hidden
                  accept=".mp4"
                  id="image-picker"
                  type="file"
                  value=""
                  onChange={onSelectVideo}
                />
              </div>
            </>
          ) : null}
          {!hideModalButton && setModalOpen ? (
            <div className="flex flex-1 justify-end">
              <button
                type="button"
                onClick={() => setModalOpen((prev) => !prev)}
              >
                <OpenFullscreenIcon className="h-5 w-5 fill-text-main" />
              </button>
            </div>
          ) : null}
        </div>
        <LinkFormModal
          open={!!AddLinkForm}
          onClose={() => setAddLinkForm(undefined)}
        >
          {!!AddLinkForm && (
            <AddLinkForm
              getEditorState={() => editorState}
              setEditorState={setEditorState}
              onOverrideContent={(content) => {
                // eslint-disable-next-line
                // @ts-ignore
                setAddLinkForm(() => content);
              }}
            />
          )}
        </LinkFormModal>
        {isExpanded && (
          <div className="flex items-center gap-2 bg-secondary-grey-light-2 px-2 py-1">
            <InlineStyles />
            <AlignTextLeftButton
              getEditorState={() => editorState}
              setEditorState={setEditorState}
              theme={{
                active: 'child:fill-text-main',
                button: 'child:w-[18px] child:h-[18px] child:fill-text-grey',
                buttonWrapper: 'flex',
              }}
            />
            <AlignTextCenterButton
              getEditorState={() => editorState}
              setEditorState={setEditorState}
              theme={{
                active: 'child:fill-text-main',
                button: 'child:w-[18px] child:h-[18px] child:fill-text-grey',
                buttonWrapper: 'flex',
              }}
            />
            <AlignTextRightButton
              getEditorState={() => editorState}
              setEditorState={setEditorState}
              theme={{
                active: 'child:fill-text-main',
                button: 'child:w-[18px] child:h-[18px] child:fill-text-grey',
                buttonWrapper: 'flex',
              }}
            />
            <ListStyles />
          </div>
        )}
        <div
          className={clsx(
            'relative p-2',
            compact ? styles['compact-editor'] : styles.editor
          )}
        >
          <Editor
            spellCheck
            blockStyleFn={blockStyleFn}
            decorators={superRichTextEditorDecorators}
            editorKey="super-rich-editor"
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={keyBindingFn}
            plugins={plugins}
            onBlur={onBlur}
            onChange={setEditorState}
          />
          {!!errorMessage && (
            <div className="absolute inset-x-2 top-2 z-10 rounded-md bg-chart-orange-light p-4 text-chart-red-dark shadow-md">
              <Typography>{errorMessage}</Typography>
            </div>
          )}
          <alignmentPlugin.AlignmentTool />
        </div>
        {!!limit && <Counter {...limit} />}
      </div>
    );
  }
);

export default SuperRichTextEditor;
