import {
  ChatBubbleOvalLeftEllipsisIcon,
  HandThumbUpIcon,
} from '@heroicons/react-v2/24/outline';
import { ArrowRightIcon, MegaphoneIcon } from '@heroicons/react-v2/24/solid';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import Link from 'next/link';
import {
  Typography,
  PinIcon,
  RichContent,
  UpdatesBlockMediaPreview,
} from '../../../index';
import CloudinaryImage from '../../ui/cloudinary-image';
import { MediaUpdateInBlock } from './block';

dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

interface Props {
  companyName: string;
  listingKey: string;
  logoUrl: string;
  marketKey: string;
  mediaUpdate: MediaUpdateInBlock;
}

export const UpdatesBlockListItem: React.FC<Props> = ({
  logoUrl,
  mediaUpdate,
}) => {
  const url = `/activity-updates/${mediaUpdate.slug}`;

  return (
    <article className="flex max-w-full flex-col items-start gap-6 overflow-hidden rounded-lg border-b pb-8 lg:flex-row lg:gap-8 lg:rounded-none">
      <div className="relative aspect-[18/10] w-full shrink-0 md:aspect-[2/1] lg:aspect-square lg:w-64">
        <Link
          className="absolute inset-0 h-full w-full overflow-hidden rounded-lg object-cover"
          href={url}
        >
          <div className="absolute inset-0 rounded-lg bg-gray-100 ring-1 ring-inset ring-gray-200" />
          <div
            className="absolute left-[50%] top-[50%] flex h-[64px] w-[64px] -translate-x-1/2 -translate-y-1/2 items-center justify-center overflow-hidden rounded-full bg-transparent fill-gray-200 ring-inset ring-gray-200
          "
          >
            {logoUrl ? (
              <CloudinaryImage
                alt="Logo"
                className="h-full w-full object-contain"
                height={250}
                src={logoUrl}
                width={250}
              />
            ) : (
              <MegaphoneIcon className="h-8 w-8 fill-inherit" />
            )}
          </div>
          <div className="relative block h-full w-full">
            <UpdatesBlockMediaPreview mediaUpdate={mediaUpdate} />
          </div>
        </Link>
      </div>
      <div className="flex h-full w-full max-w-xl flex-col items-start justify-between gap-4">
        <div className="flex flex-col gap-4">
          {mediaUpdate.isPinned ? (
            <div className="flex items-center gap-x-2">
              <PinIcon
                className="text-company-primary"
                height={14}
                width={14}
              />
              <Typography className="text-company-primary" variant="subtitle-2">
                Pinned
              </Typography>
            </div>
          ) : null}

          <time
            className="font-body text-sm font-normal leading-5 text-hubs-secondary"
            dateTime={dayjs(mediaUpdate.postedAt).format()}
          >
            {dayjs(mediaUpdate.postedAt).format('LL')}
          </time>

          <div className="group relative max-w-[500px] cursor-pointer transition-opacity">
            <Link className="absolute inset-0 z-10" href={url} />
            <Typography
              className="mb-4 !text-xl text-company-primary group-hover:opacity-90"
              component="h3"
              variant="heading-4"
            >
              {mediaUpdate.title}
            </Typography>

            {mediaUpdate.content?.content ? (
              <div className="line-clamp-3 text-hubs-secondary group-hover:opacity-90">
                <RichContent plaintext content={mediaUpdate.content.content} />
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex items-center gap-4 text-hubs-secondary">
          <div className="relative flex items-center gap-1 ">
            <HandThumbUpIcon className="h-5 w-5" />
            <Typography variant="body-small">{mediaUpdate.likes}</Typography>
          </div>
          <div className="relative flex items-center gap-1">
            <ChatBubbleOvalLeftEllipsisIcon className="h-5 w-5" />
            <Typography variant="body-small">
              {mediaUpdate.questionCount}
            </Typography>
          </div>
        </div>
        <div className="lg:hidden">
          <Link href={url}>
            <Typography
              className="flex items-center gap-1.5"
              variant="subtitle-2"
            >
              <span>See more</span>
              <ArrowRightIcon className="mt-0.5 h-3.5 w-3.5" />
            </Typography>
          </Link>
        </div>
      </div>
    </article>
  );
};
